/* tslint:disable */
/* eslint-disable */
export enum DayName {
  Monday = 'monday',
  Wednesday = 'wednesday',
  Tuesday = 'tuesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../interfaces/user-state.interface';

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectUserLoading = createSelector(
  selectUserState,
  (state) => state.loading,
);

export const selectSessionExists = createSelector(
  selectUserState,
  (state) => state.sessionExists,
);

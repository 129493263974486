import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from '@app/environments/environment';
import { IamModule, OPTIONS } from '@fullstackagency/iam';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { ContentComponent } from './content.component';
import { MessagesComponent } from './messages.component';

const PRIME_IMPORTS = [ButtonModule, InputTextModule, MessagesModule];

@NgModule({
  declarations: [ContentComponent, MessagesComponent],
  exports: [ContentComponent, MessagesComponent],
  imports: [CommonModule, IamModule, PRIME_IMPORTS],
  providers: [
    {
      provide: OPTIONS,
      useValue: {
        client: {
          basePath: environment['iam.basePath'],
        },
        paths: {
          error: '/error',
          index: '/',
          login: '/account/login',
          recovery: '/account/password-recovery',
          registration: '/account/sign-up',
          settings: '/settings/profile',
          verification: '/account/verification',
        },
      },
    },
  ],
})
export class IamWithPrimeModule {}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Company } from '@generated/models';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs';
import { selectCompany } from '../store/selectors/company.selector';

export const hasActivityGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectCompany).pipe(
    filter((company): company is Company => company !== null),
    take(1),
    map((company) => {
      return company.activities.length
        ? true
        : router.createUrlTree(['/', 'welcome']);
    }),
  );
};

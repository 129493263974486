import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-megamenu',
  template: `
    <ul
      class="layout-megamenu"
      [ngClass]="{ 'layout-megamenu-active fadeInDown': megaMenuActive }"
      (clickOutside)="megaMenuActive && megaMenuButtonClick.emit($event)"
    >
      <li [ngClass]="{ 'active-topmenuitem': activeItem === 'megaMenuItem-1' }">
        <a (click)="megaMenuItemClick('megaMenuItem-1')"
          >JavaServer Faces <i class="pi pi-angle-down"></i
        ></a>
        <ul>
          <li class="active-row ">
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>PrimeFaces</h5>
              <span>UI Components for JSF</span>
            </span>
          </li>
          <li>
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>Premium Templates</h5>
              <span>UI Components for JSF</span>
            </span>
          </li>
          <li>
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>Extensions</h5>
              <span>UI Components for JSF</span>
            </span>
          </li>
        </ul>
      </li>
      <li [ngClass]="{ 'active-topmenuitem': activeItem === 'megaMenuItem-2' }">
        <a (click)="megaMenuItemClick('megaMenuItem-2')"
          >Angular <i class="pi pi-angle-down"></i
        ></a>
        <ul>
          <li>
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>PrimeNG</h5>
              <span>UI Components for Angular</span>
            </span>
          </li>
          <li>
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>Premium Templates</h5>
              <span>UI Components for Angular</span>
            </span>
          </li>
        </ul>
      </li>
      <li [ngClass]="{ 'active-topmenuitem': activeItem === 'megaMenuItem-3' }">
        <a (click)="megaMenuItemClick('megaMenuItem-3')"
          >React <i class="pi pi-angle-down"></i
        ></a>
        <ul>
          <li>
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>PrimeReact</h5>
              <span>UI Components for React</span>
            </span>
          </li>
          <li class="active-row">
            <i class="pi pi-circle-on"></i>
            <span>
              <h5>Premium Templates</h5>
              <span>UI Components for React</span>
            </span>
          </li>
        </ul>
      </li>
    </ul>
  `,
  styles: [],
})
export class MegamenuComponent {
  @Input() megaMenuActive: boolean | undefined = false;
  @Output() megaMenuButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  activeItem!: string | null;

  megaMenuItemClick(index: string) {
    this.activeItem = this.activeItem === index ? null : index;
  }
}

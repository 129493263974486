/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddAppointment } from '../models/add-appointment';
import { AddBreed } from '../models/add-breed';
import { AddCustomer } from '../models/add-customer';
import { AddGrooming } from '../models/add-grooming';
import { AddOperator } from '../models/add-operator';
import { AddOutcome } from '../models/add-outcome';
import { AddPet } from '../models/add-pet';
import { AddPetService } from '../models/add-pet-service';
import { AddPetServiceAddon } from '../models/add-pet-service-addon';
import { AddPetServiceAddonTier } from '../models/add-pet-service-addon-tier';
import { AddPetServiceTier } from '../models/add-pet-service-tier';
import { Appointment } from '../models/appointment';
import { AppointmentsRecap } from '../models/appointments-recap';
import { Breed } from '../models/breed';
import { BrowseAppointments } from '../models/browse-appointments';
import { BrowseAvailabilities } from '../models/browse-availabilities';
import { BrowseBreeds } from '../models/browse-breeds';
import { BrowseCustomers } from '../models/browse-customers';
import { BrowseOperators } from '../models/browse-operators';
import { BrowseOutcomes } from '../models/browse-outcomes';
import { BrowsePetServices } from '../models/browse-pet-services';
import { BrowsePets } from '../models/browse-pets';
import { BrowsePlans } from '../models/browse-plans';
import { Configuration } from '../models/configuration';
import { Customer } from '../models/customer';
import { Grooming } from '../models/grooming';
import { ListAppointments } from '../models/list-appointments';
import { ListAvailabilities } from '../models/list-availabilities';
import { ListBreeds } from '../models/list-breeds';
import { ListCustomers } from '../models/list-customers';
import { ListOperators } from '../models/list-operators';
import { ListOutcomes } from '../models/list-outcomes';
import { ListPetServices } from '../models/list-pet-services';
import { ListPets } from '../models/list-pets';
import { ListPlans } from '../models/list-plans';
import { Operator } from '../models/operator';
import { Outcome } from '../models/outcome';
import { Pet } from '../models/pet';
import { PetService } from '../models/pet-service';
import { ShortUrl } from '../models/short-url';
import { UpdateAppointmentStatus } from '../models/update-appointment-status';
import { UpdateCustomer } from '../models/update-customer';
import { UpdateGrooming } from '../models/update-grooming';
import { UpdateGroomingAvailabilityRules } from '../models/update-grooming-availability-rules';
import { UpdateGroomingBillingInfo } from '../models/update-grooming-billing-info';
import { UpdateGroomingTreatedPets } from '../models/update-grooming-treated-pets';
import { UpdateOperator } from '../models/update-operator';
import { UpdateOperatorAvailabilityRules } from '../models/update-operator-availability-rules';
import { UpdateOperatorTreatedPets } from '../models/update-operator-treated-pets';
import { UpdateOutcome } from '../models/update-outcome';
import { UpdatePet } from '../models/update-pet';
import { UpdatePetService } from '../models/update-pet-service';
import { UpdatePetServiceAddon } from '../models/update-pet-service-addon';
import { UpdatePetServiceAddonTier } from '../models/update-pet-service-addon-tier';
import { UpdatePetServiceTier } from '../models/update-pet-service-tier';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `browseBreeds()` */
  static readonly BrowseBreedsPath = '/private/breeds/browse';

  /**
   * Browse Breeds.
   *
   * Browse Breeds
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseBreeds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseBreeds$Response(
    params?: {
      body?: BrowseBreeds;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListBreeds>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseBreedsPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListBreeds>;
        }),
      );
  }

  /**
   * Browse Breeds.
   *
   * Browse Breeds
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseBreeds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseBreeds(
    params?: {
      body?: BrowseBreeds;
    },
    context?: HttpContext,
  ): Observable<ListBreeds> {
    return this.browseBreeds$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListBreeds>): ListBreeds => r.body),
    );
  }

  /** Path part for operation `getBreed()` */
  static readonly GetBreedPath = '/private/breeds/{breedId}';

  /**
   * Read Breed.
   *
   * Read Breed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBreed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBreed$Response(
    params: {
      /**
       * Breed Id
       */
      breedId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Breed>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetBreedPath, 'get');
    if (params) {
      rb.path('breedId', params.breedId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Breed>;
        }),
      );
  }

  /**
   * Read Breed.
   *
   * Read Breed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBreed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBreed(
    params: {
      /**
       * Breed Id
       */
      breedId: string;
    },
    context?: HttpContext,
  ): Observable<Breed> {
    return this.getBreed$Response(params, context).pipe(
      map((r: StrictHttpResponse<Breed>): Breed => r.body),
    );
  }

  /** Path part for operation `addBreed()` */
  static readonly AddBreedPath = '/private/breeds';

  /**
   * Add Breed.
   *
   * Add Breed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBreed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBreed$Response(
    params?: {
      body?: AddBreed;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Breed>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddBreedPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Breed>;
        }),
      );
  }

  /**
   * Add Breed.
   *
   * Add Breed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBreed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBreed(
    params?: {
      body?: AddBreed;
    },
    context?: HttpContext,
  ): Observable<Breed> {
    return this.addBreed$Response(params, context).pipe(
      map((r: StrictHttpResponse<Breed>): Breed => r.body),
    );
  }

  /** Path part for operation `browseCustomers()` */
  static readonly BrowseCustomersPath = '/private/customers/browse';

  /**
   * Browse Customers.
   *
   * Browse Customers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseCustomers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseCustomers$Response(
    params?: {
      term?: string;
      body?: BrowseCustomers;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListCustomers>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseCustomersPath,
      'post',
    );
    if (params) {
      rb.query('term', params.term, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListCustomers>;
        }),
      );
  }

  /**
   * Browse Customers.
   *
   * Browse Customers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseCustomers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseCustomers(
    params?: {
      term?: string;
      body?: BrowseCustomers;
    },
    context?: HttpContext,
  ): Observable<ListCustomers> {
    return this.browseCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListCustomers>): ListCustomers => r.body),
    );
  }

  /** Path part for operation `getCustomer()` */
  static readonly GetCustomerPath = '/private/customers/{customerId}';

  /**
   * Read Customer.
   *
   * Read Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer$Response(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<
    StrictHttpResponse<
      Customer & {
        pets: Array<Pet>;
      }
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetCustomerPath,
      'get',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >;
        }),
      );
  }

  /**
   * Read Customer.
   *
   * Read Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomer(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<
    Customer & {
      pets: Array<Pet>;
    }
  > {
    return this.getCustomer$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >,
        ): Customer & {
          pets: Array<Pet>;
        } => r.body,
      ),
    );
  }

  /** Path part for operation `updateCustomer()` */
  static readonly UpdateCustomerPath = '/private/customers/{customerId}';

  /**
   * Update Customer.
   *
   * Update Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer$Response(
    params: {
      customerId: string;
      body?: UpdateCustomer;
    },
    context?: HttpContext,
  ): Observable<
    StrictHttpResponse<
      Customer & {
        pets: Array<Pet>;
      }
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateCustomerPath,
      'put',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >;
        }),
      );
  }

  /**
   * Update Customer.
   *
   * Update Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomer(
    params: {
      customerId: string;
      body?: UpdateCustomer;
    },
    context?: HttpContext,
  ): Observable<
    Customer & {
      pets: Array<Pet>;
    }
  > {
    return this.updateCustomer$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >,
        ): Customer & {
          pets: Array<Pet>;
        } => r.body,
      ),
    );
  }

  /** Path part for operation `deleteCustomer()` */
  static readonly DeleteCustomerPath = '/private/customers/{customerId}';

  /**
   * Delete Customer.
   *
   * Delete Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer$Response(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteCustomerPath,
      'delete',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Customer.
   *
   * Delete Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomer(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `addCustomer()` */
  static readonly AddCustomerPath = '/private/customers';

  /**
   * Add Customer.
   *
   * Add Customer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomer$Response(
    params?: {
      body?: AddCustomer;
    },
    context?: HttpContext,
  ): Observable<
    StrictHttpResponse<
      Customer & {
        pets: Array<Pet>;
      }
    >
  > {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddCustomerPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >;
        }),
      );
  }

  /**
   * Add Customer.
   *
   * Add Customer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomer(
    params?: {
      body?: AddCustomer;
    },
    context?: HttpContext,
  ): Observable<
    Customer & {
      pets: Array<Pet>;
    }
  > {
    return this.addCustomer$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<
            Customer & {
              pets: Array<Pet>;
            }
          >,
        ): Customer & {
          pets: Array<Pet>;
        } => r.body,
      ),
    );
  }

  /** Path part for operation `addPet()` */
  static readonly AddPetPath = '/private/customers/{customerId}/pets';

  /**
   * Add Pet.
   *
   * Add Pet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPet$Response(
    params: {
      customerId: string;
      body?: AddPet;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Pet>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.AddPetPath, 'post');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Pet>;
        }),
      );
  }

  /**
   * Add Pet.
   *
   * Add Pet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPet(
    params: {
      customerId: string;
      body?: AddPet;
    },
    context?: HttpContext,
  ): Observable<Pet> {
    return this.addPet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pet>): Pet => r.body),
    );
  }

  /** Path part for operation `updatePet()` */
  static readonly UpdatePetPath =
    '/private/customers/{customerId}/pets/{petId}';

  /**
   * Update Pet.
   *
   * Update Pet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePet$Response(
    params: {
      customerId: string;
      petId: string;
      body?: UpdatePet;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Pet>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdatePetPath,
      'put',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('petId', params.petId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Pet>;
        }),
      );
  }

  /**
   * Update Pet.
   *
   * Update Pet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePet(
    params: {
      customerId: string;
      petId: string;
      body?: UpdatePet;
    },
    context?: HttpContext,
  ): Observable<Pet> {
    return this.updatePet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Pet>): Pet => r.body),
    );
  }

  /** Path part for operation `deletePet()` */
  static readonly DeletePetPath =
    '/private/customers/{customerId}/pets/{petId}';

  /**
   * Delete Pet.
   *
   * Delete Pet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePet()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePet$Response(
    params: {
      customerId: string;
      petId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetPath,
      'delete',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('petId', params.petId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Pet.
   *
   * Delete Pet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePet(
    params: {
      customerId: string;
      petId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `browsePets()` */
  static readonly BrowsePetsPath = '/private/pets/browse';

  /**
   * Browse Pets.
   *
   * Browse Pets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browsePets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePets$Response(
    params?: {
      term?: string;
      body?: BrowsePets;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListPets>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowsePetsPath,
      'post',
    );
    if (params) {
      rb.query('term', params.term, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListPets>;
        }),
      );
  }

  /**
   * Browse Pets.
   *
   * Browse Pets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browsePets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePets(
    params?: {
      term?: string;
      body?: BrowsePets;
    },
    context?: HttpContext,
  ): Observable<ListPets> {
    return this.browsePets$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListPets>): ListPets => r.body),
    );
  }

  /** Path part for operation `getPet()` */
  static readonly GetPetPath = '/private/pets/{petId}';

  /**
   * Read Pet.
   *
   * Read Pet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPet$Response(
    params: {
      petId: string;
    },
    context?: HttpContext,
  ): Observable<
    StrictHttpResponse<
      Pet & {
        owner?: Customer;
      }
    >
  > {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetPetPath, 'get');
    if (params) {
      rb.path('petId', params.petId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<
            Pet & {
              owner?: Customer;
            }
          >;
        }),
      );
  }

  /**
   * Read Pet.
   *
   * Read Pet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPet(
    params: {
      petId: string;
    },
    context?: HttpContext,
  ): Observable<
    Pet & {
      owner?: Customer;
    }
  > {
    return this.getPet$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<
            Pet & {
              owner?: Customer;
            }
          >,
        ): Pet & {
          owner?: Customer;
        } => r.body,
      ),
    );
  }

  /** Path part for operation `browseOperators()` */
  static readonly BrowseOperatorsPath = '/private/operators/browse';

  /**
   * Browse Operators.
   *
   * Browse Operators
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseOperators()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseOperators$Response(
    params?: {
      body?: BrowseOperators;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListOperators>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseOperatorsPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListOperators>;
        }),
      );
  }

  /**
   * Browse Operators.
   *
   * Browse Operators
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseOperators$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseOperators(
    params?: {
      body?: BrowseOperators;
    },
    context?: HttpContext,
  ): Observable<ListOperators> {
    return this.browseOperators$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListOperators>): ListOperators => r.body),
    );
  }

  /** Path part for operation `getOperator()` */
  static readonly GetOperatorPath = '/private/operators/{operatorId}';

  /**
   * Read Operator.
   *
   * Read Operator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOperator()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperator$Response(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetOperatorPath,
      'get',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Read Operator.
   *
   * Read Operator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOperator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOperator(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.getOperator$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `updateOperator()` */
  static readonly UpdateOperatorPath = '/private/operators/{operatorId}';

  /**
   * Update Operator.
   *
   * Update Operator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOperator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperator$Response(
    params: {
      operatorId: string;
      body?: UpdateOperator;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateOperatorPath,
      'put',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Update Operator.
   *
   * Update Operator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOperator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperator(
    params: {
      operatorId: string;
      body?: UpdateOperator;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.updateOperator$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `deleteOperator()` */
  static readonly DeleteOperatorPath = '/private/operators/{operatorId}';

  /**
   * Delete Operator.
   *
   * Delete Operator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOperator()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOperator$Response(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteOperatorPath,
      'delete',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Operator.
   *
   * Delete Operator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOperator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOperator(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteOperator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `addOperator()` */
  static readonly AddOperatorPath = '/private/operators';

  /**
   * Add Operator.
   *
   * Add Operator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOperator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOperator$Response(
    params?: {
      body?: AddOperator;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddOperatorPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Add Operator.
   *
   * Add Operator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOperator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOperator(
    params?: {
      body?: AddOperator;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.addOperator$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `toggleEnabledOperator()` */
  static readonly ToggleEnabledOperatorPath =
    '/private/operators/{operatorId}/toggleEnabled';

  /**
   * Toggle Enabled Operator.
   *
   * Toggle Enabled Operator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleEnabledOperator()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledOperator$Response(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ToggleEnabledOperatorPath,
      'patch',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Toggle Enabled Operator.
   *
   * Toggle Enabled Operator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleEnabledOperator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledOperator(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.toggleEnabledOperator$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `updateOperatorAvailabilityRules()` */
  static readonly UpdateOperatorAvailabilityRulesPath =
    '/private/operators/{operatorId}/availabilityRules';

  /**
   * Update Operator AvailabilityRules.
   *
   * Update Operator AvailabilityRules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOperatorAvailabilityRules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperatorAvailabilityRules$Response(
    params: {
      operatorId: string;
      body?: UpdateOperatorAvailabilityRules;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateOperatorAvailabilityRulesPath,
      'put',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Update Operator AvailabilityRules.
   *
   * Update Operator AvailabilityRules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOperatorAvailabilityRules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperatorAvailabilityRules(
    params: {
      operatorId: string;
      body?: UpdateOperatorAvailabilityRules;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.updateOperatorAvailabilityRules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `updateOperatorTreatedpets()` */
  static readonly UpdateOperatorTreatedpetsPath =
    '/private/operators/{operatorId}/treatedPets';

  /**
   * Update Operator TreatedPets.
   *
   * Update Operator TreatedPets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOperatorTreatedpets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperatorTreatedpets$Response(
    params: {
      operatorId: string;
      body?: UpdateOperatorTreatedPets;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Operator>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateOperatorTreatedpetsPath,
      'put',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Operator>;
        }),
      );
  }

  /**
   * Update Operator TreatedPets.
   *
   * Update Operator TreatedPets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOperatorTreatedpets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOperatorTreatedpets(
    params: {
      operatorId: string;
      body?: UpdateOperatorTreatedPets;
    },
    context?: HttpContext,
  ): Observable<Operator> {
    return this.updateOperatorTreatedpets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Operator>): Operator => r.body),
    );
  }

  /** Path part for operation `browsePetServices()` */
  static readonly BrowsePetServicesPath = '/private/petServices/browse';

  /**
   * Browse PetServices.
   *
   * Browse PetServices
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browsePetServices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePetServices$Response(
    params?: {
      body?: BrowsePetServices;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListPetServices>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowsePetServicesPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListPetServices>;
        }),
      );
  }

  /**
   * Browse PetServices.
   *
   * Browse PetServices
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browsePetServices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePetServices(
    params?: {
      body?: BrowsePetServices;
    },
    context?: HttpContext,
  ): Observable<ListPetServices> {
    return this.browsePetServices$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListPetServices>): ListPetServices => r.body),
    );
  }

  /** Path part for operation `getPetService()` */
  static readonly GetPetServicePath = '/private/petServices/{petServiceId}';

  /**
   * Read PetService.
   *
   * Read PetService
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPetService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPetService$Response(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetPetServicePath,
      'get',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Read PetService.
   *
   * Read PetService
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPetService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPetService(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.getPetService$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `updatePetService()` */
  static readonly UpdatePetServicePath = '/private/petServices/{petServiceId}';

  /**
   * Update PetService.
   *
   * Update PetService
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePetService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetService$Response(
    params: {
      petServiceId: string;
      body?: UpdatePetService;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdatePetServicePath,
      'put',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Update PetService.
   *
   * Update PetService
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePetService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetService(
    params: {
      petServiceId: string;
      body?: UpdatePetService;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.updatePetService$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `deletePetService()` */
  static readonly DeletePetServicePath = '/private/petServices/{petServiceId}';

  /**
   * Delete PetService.
   *
   * Delete PetService
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePetService()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetService$Response(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetServicePath,
      'delete',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete PetService.
   *
   * Delete PetService
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePetService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetService(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePetService$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `addPetService()` */
  static readonly AddPetServicePath = '/private/petServices';

  /**
   * Add PetService.
   *
   * Add PetService
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPetService()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetService$Response(
    params?: {
      body?: AddPetService;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddPetServicePath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Add PetService.
   *
   * Add PetService
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPetService$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetService(
    params?: {
      body?: AddPetService;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.addPetService$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `toggleEnabledPetService()` */
  static readonly ToggleEnabledPetServicePath =
    '/private/petServices/{petServiceId}/toggleEnabled';

  /**
   * Toggle Enabled PetService.
   *
   * Toggle Enabled PetService
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleEnabledPetService()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledPetService$Response(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ToggleEnabledPetServicePath,
      'patch',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Toggle Enabled PetService.
   *
   * Toggle Enabled PetService
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleEnabledPetService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledPetService(
    params: {
      petServiceId: string;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.toggleEnabledPetService$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `toggleEnabledPetServiceAddon()` */
  static readonly ToggleEnabledPetServiceAddonPath =
    '/private/petServices/{petServiceId}/addons/{addonId}/toggleEnabled';

  /**
   * Toggle Enabled PetService Addon.
   *
   * Toggle Enabled PetService Addon
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `toggleEnabledPetServiceAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledPetServiceAddon$Response(
    params: {
      petServiceId: string;
      addonId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ToggleEnabledPetServiceAddonPath,
      'patch',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Toggle Enabled PetService Addon.
   *
   * Toggle Enabled PetService Addon
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `toggleEnabledPetServiceAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  toggleEnabledPetServiceAddon(
    params: {
      petServiceId: string;
      addonId: string;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.toggleEnabledPetServiceAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `addPetServiceAddon()` */
  static readonly AddPetServiceAddonPath =
    '/private/petServices/{petServiceId}/addons';

  /**
   * Add PetService Addon.
   *
   * Add PetService Addon
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPetServiceAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceAddon$Response(
    params: {
      petServiceId: string;
      body?: AddPetServiceAddon;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddPetServiceAddonPath,
      'post',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Add PetService Addon.
   *
   * Add PetService Addon
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPetServiceAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceAddon(
    params: {
      petServiceId: string;
      body?: AddPetServiceAddon;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.addPetServiceAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `updatePetServiceAddon()` */
  static readonly UpdatePetServiceAddonPath =
    '/private/petServices/{petServiceId}/addons/{addonId}';

  /**
   * Update PetService Addon.
   *
   * Update PetService Addon
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePetServiceAddon()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceAddon$Response(
    params: {
      petServiceId: string;
      addonId: string;
      body?: UpdatePetServiceAddon;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdatePetServiceAddonPath,
      'put',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Update PetService Addon.
   *
   * Update PetService Addon
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePetServiceAddon$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceAddon(
    params: {
      petServiceId: string;
      addonId: string;
      body?: UpdatePetServiceAddon;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.updatePetServiceAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `deletePetServiceAddon()` */
  static readonly DeletePetServiceAddonPath =
    '/private/petServices/{petServiceId}/addons/{addonId}';

  /**
   * Delete PetService Addon.
   *
   * Delete PetService Addon
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePetServiceAddon()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceAddon$Response(
    params: {
      petServiceId: string;
      addonId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetServiceAddonPath,
      'delete',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete PetService Addon.
   *
   * Delete PetService Addon
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePetServiceAddon$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceAddon(
    params: {
      petServiceId: string;
      addonId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePetServiceAddon$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `addPetServiceAddonTier()` */
  static readonly AddPetServiceAddonTierPath =
    '/private/petServices/{petServiceId}/addons/{addonId}/tiers';

  /**
   * Add PetService Addon Tier.
   *
   * Add PetService Addon Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPetServiceAddonTier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceAddonTier$Response(
    params: {
      petServiceId: string;
      addonId: string;
      body?: AddPetServiceAddonTier;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddPetServiceAddonTierPath,
      'post',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Add PetService Addon Tier.
   *
   * Add PetService Addon Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPetServiceAddonTier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceAddonTier(
    params: {
      petServiceId: string;
      addonId: string;
      body?: AddPetServiceAddonTier;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.addPetServiceAddonTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `updatePetServiceAddonTier()` */
  static readonly UpdatePetServiceAddonTierPath =
    '/private/petServices/{petServiceId}/addons/{addonId}/tiers/{tierId}';

  /**
   * Update PetService Addon Tier.
   *
   * Update PetService Addon Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePetServiceAddonTier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceAddonTier$Response(
    params: {
      petServiceId: string;
      addonId: string;
      tierId: string;
      body?: UpdatePetServiceAddonTier;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdatePetServiceAddonTierPath,
      'put',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
      rb.path('tierId', params.tierId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Update PetService Addon Tier.
   *
   * Update PetService Addon Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePetServiceAddonTier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceAddonTier(
    params: {
      petServiceId: string;
      addonId: string;
      tierId: string;
      body?: UpdatePetServiceAddonTier;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.updatePetServiceAddonTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `deletePetServiceAddonTier()` */
  static readonly DeletePetServiceAddonTierPath =
    '/private/petServices/{petServiceId}/addons/{addonId}/tiers/{tierId}';

  /**
   * Delete PetService Addon Tier.
   *
   * Delete PetService Addon Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePetServiceAddonTier()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceAddonTier$Response(
    params: {
      petServiceId: string;
      addonId: string;
      tierId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetServiceAddonTierPath,
      'delete',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('addonId', params.addonId, {});
      rb.path('tierId', params.tierId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete PetService Addon Tier.
   *
   * Delete PetService Addon Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePetServiceAddonTier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceAddonTier(
    params: {
      petServiceId: string;
      addonId: string;
      tierId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePetServiceAddonTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `addPetServiceTier()` */
  static readonly AddPetServiceTierPath =
    '/private/petServices/{petServiceId}/tiers';

  /**
   * Add PetService Tier.
   *
   * Add PetService Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPetServiceTier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceTier$Response(
    params: {
      petServiceId: string;
      body?: AddPetServiceTier;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddPetServiceTierPath,
      'post',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Add PetService Tier.
   *
   * Add PetService Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPetServiceTier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPetServiceTier(
    params: {
      petServiceId: string;
      body?: AddPetServiceTier;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.addPetServiceTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `updatePetServiceTier()` */
  static readonly UpdatePetServiceTierPath =
    '/private/petServices/{petServiceId}/tiers/{tierId}';

  /**
   * Update PetService Tier.
   *
   * Update PetService Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePetServiceTier()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceTier$Response(
    params: {
      petServiceId: string;
      tierId: string;
      body?: UpdatePetServiceTier;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<PetService>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdatePetServiceTierPath,
      'put',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('tierId', params.tierId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<PetService>;
        }),
      );
  }

  /**
   * Update PetService Tier.
   *
   * Update PetService Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePetServiceTier$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePetServiceTier(
    params: {
      petServiceId: string;
      tierId: string;
      body?: UpdatePetServiceTier;
    },
    context?: HttpContext,
  ): Observable<PetService> {
    return this.updatePetServiceTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<PetService>): PetService => r.body),
    );
  }

  /** Path part for operation `deletePetServiceTier()` */
  static readonly DeletePetServiceTierPath =
    '/private/petServices/{petServiceId}/tiers/{tierId}';

  /**
   * Delete PetService Tier.
   *
   * Delete PetService Tier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePetServiceTier()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceTier$Response(
    params: {
      petServiceId: string;
      tierId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetServiceTierPath,
      'delete',
    );
    if (params) {
      rb.path('petServiceId', params.petServiceId, {});
      rb.path('tierId', params.tierId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete PetService Tier.
   *
   * Delete PetService Tier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePetServiceTier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetServiceTier(
    params: {
      petServiceId: string;
      tierId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePetServiceTier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `getGrooming()` */
  static readonly GetGroomingPath = '/private/groomings';

  /**
   * Get Grooming.
   *
   * Get Grooming
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGrooming()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrooming$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetGroomingPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Get Grooming.
   *
   * Get Grooming
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGrooming$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGrooming(params?: {}, context?: HttpContext): Observable<Grooming> {
    return this.getGrooming$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `addGrooming()` */
  static readonly AddGroomingPath = '/private/groomings';

  /**
   * Add Grooming.
   *
   * Add Grooming
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGrooming()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGrooming$Response(
    params?: {
      body?: AddGrooming;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddGroomingPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Add Grooming.
   *
   * Add Grooming
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addGrooming$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGrooming(
    params?: {
      body?: AddGrooming;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.addGrooming$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `groomingByToken()` */
  static readonly GroomingByTokenPath = '/public/groomings/token/{token}';

  /**
   * Get Grooming By Token.
   *
   * Get Grooming
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groomingByToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  groomingByToken$Response(
    params: {
      token: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GroomingByTokenPath,
      'get',
    );
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Get Grooming By Token.
   *
   * Get Grooming
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groomingByToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  groomingByToken(
    params: {
      token: string;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.groomingByToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `updateGrooming()` */
  static readonly UpdateGroomingPath = '/private/groomings/{groomingId}';

  /**
   * Update Grooming.
   *
   * Update Grooming
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGrooming()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGrooming$Response(
    params: {
      groomingId: string;
      body?: UpdateGrooming;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateGroomingPath,
      'put',
    );
    if (params) {
      rb.path('groomingId', params.groomingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Update Grooming.
   *
   * Update Grooming
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGrooming$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGrooming(
    params: {
      groomingId: string;
      body?: UpdateGrooming;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.updateGrooming$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `updateGroomingBillinginfo()` */
  static readonly UpdateGroomingBillinginfoPath =
    '/private/groomings/{groomingId}/billingInfo';

  /**
   * Update Grooming BillingInfo.
   *
   * Update Grooming BillingInfo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroomingBillinginfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingBillinginfo$Response(
    params: {
      groomingId: string;
      body?: UpdateGroomingBillingInfo;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateGroomingBillinginfoPath,
      'put',
    );
    if (params) {
      rb.path('groomingId', params.groomingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Update Grooming BillingInfo.
   *
   * Update Grooming BillingInfo
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroomingBillinginfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingBillinginfo(
    params: {
      groomingId: string;
      body?: UpdateGroomingBillingInfo;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.updateGroomingBillinginfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `updateGroomingTreatedpets()` */
  static readonly UpdateGroomingTreatedpetsPath =
    '/private/groomings/{groomingId}/treatedPets';

  /**
   * Update Grooming TreatedPets.
   *
   * Update Grooming TreatedPets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroomingTreatedpets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingTreatedpets$Response(
    params: {
      groomingId: string;
      body?: UpdateGroomingTreatedPets;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateGroomingTreatedpetsPath,
      'put',
    );
    if (params) {
      rb.path('groomingId', params.groomingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Update Grooming TreatedPets.
   *
   * Update Grooming TreatedPets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroomingTreatedpets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingTreatedpets(
    params: {
      groomingId: string;
      body?: UpdateGroomingTreatedPets;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.updateGroomingTreatedpets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `updateGroomingAvailabilityrules()` */
  static readonly UpdateGroomingAvailabilityrulesPath =
    '/private/groomings/{groomingId}/availabilityRules';

  /**
   * Update Grooming AvailabilityRules.
   *
   * Update Grooming AvailabilityRules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGroomingAvailabilityrules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingAvailabilityrules$Response(
    params: {
      groomingId: string;
      body?: UpdateGroomingAvailabilityRules;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Grooming>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateGroomingAvailabilityrulesPath,
      'put',
    );
    if (params) {
      rb.path('groomingId', params.groomingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Grooming>;
        }),
      );
  }

  /**
   * Update Grooming AvailabilityRules.
   *
   * Update Grooming AvailabilityRules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateGroomingAvailabilityrules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGroomingAvailabilityrules(
    params: {
      groomingId: string;
      body?: UpdateGroomingAvailabilityRules;
    },
    context?: HttpContext,
  ): Observable<Grooming> {
    return this.updateGroomingAvailabilityrules$Response(params, context).pipe(
      map((r: StrictHttpResponse<Grooming>): Grooming => r.body),
    );
  }

  /** Path part for operation `browsePlans()` */
  static readonly BrowsePlansPath = '/public/plans/browse';

  /**
   * Browse Plans.
   *
   * Browse Plans
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browsePlans()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePlans$Response(
    params?: {
      body?: BrowsePlans;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListPlans>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowsePlansPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListPlans>;
        }),
      );
  }

  /**
   * Browse Plans.
   *
   * Browse Plans
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browsePlans$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browsePlans(
    params?: {
      body?: BrowsePlans;
    },
    context?: HttpContext,
  ): Observable<ListPlans> {
    return this.browsePlans$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListPlans>): ListPlans => r.body),
    );
  }

  /** Path part for operation `browseAppointments()` */
  static readonly BrowseAppointmentsPath = '/private/appointments/browse';

  /**
   * Browse Appointments.
   *
   * Browse Appointments
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseAppointments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseAppointments$Response(
    params?: {
      body?: BrowseAppointments;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListAppointments>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseAppointmentsPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListAppointments>;
        }),
      );
  }

  /**
   * Browse Appointments.
   *
   * Browse Appointments
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseAppointments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseAppointments(
    params?: {
      body?: BrowseAppointments;
    },
    context?: HttpContext,
  ): Observable<ListAppointments> {
    return this.browseAppointments$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<ListAppointments>): ListAppointments => r.body,
      ),
    );
  }

  /** Path part for operation `getAppointment()` */
  static readonly GetAppointmentPath = '/private/appointments/{appointmentId}';

  /**
   * Get Appointment.
   *
   * Get Appointment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppointment$Response(
    params: {
      appointmentId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Appointment>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetAppointmentPath,
      'get',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Appointment>;
        }),
      );
  }

  /**
   * Get Appointment.
   *
   * Get Appointment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppointment(
    params: {
      appointmentId: string;
    },
    context?: HttpContext,
  ): Observable<Appointment> {
    return this.getAppointment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Appointment>): Appointment => r.body),
    );
  }

  /** Path part for operation `deleteAppointment()` */
  static readonly DeleteAppointmentPath =
    '/private/appointments/{appointmentId}';

  /**
   * Delete Appointment.
   *
   * Delete Appointment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAppointment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAppointment$Response(
    params: {
      appointmentId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteAppointmentPath,
      'delete',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Appointment.
   *
   * Delete Appointment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAppointment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAppointment(
    params: {
      appointmentId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteAppointment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `getAppointmentByToken()` */
  static readonly GetAppointmentByTokenPath =
    '/public/appointments/token/{token}';

  /**
   * Get Appointment By Token.
   *
   * Get Appointment By Token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAppointmentByToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppointmentByToken$Response(
    params: {
      token: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Appointment>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetAppointmentByTokenPath,
      'get',
    );
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Appointment>;
        }),
      );
  }

  /**
   * Get Appointment By Token.
   *
   * Get Appointment By Token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAppointmentByToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAppointmentByToken(
    params: {
      token: string;
    },
    context?: HttpContext,
  ): Observable<Appointment> {
    return this.getAppointmentByToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<Appointment>): Appointment => r.body),
    );
  }

  /** Path part for operation `updateAppointmentStatus()` */
  static readonly UpdateAppointmentStatusPath =
    '/private/appointments/{appointmentId}/updateStatus';

  /**
   * Update Appointment Status.
   *
   * Update Appointment Status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAppointmentStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentStatus$Response(
    params: {
      appointmentId: string;
      body?: UpdateAppointmentStatus;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Appointment>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateAppointmentStatusPath,
      'patch',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Appointment>;
        }),
      );
  }

  /**
   * Update Appointment Status.
   *
   * Update Appointment Status
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAppointmentStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentStatus(
    params: {
      appointmentId: string;
      body?: UpdateAppointmentStatus;
    },
    context?: HttpContext,
  ): Observable<Appointment> {
    return this.updateAppointmentStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<Appointment>): Appointment => r.body),
    );
  }

  /** Path part for operation `updateAppointmentStatusByToken()` */
  static readonly UpdateAppointmentStatusByTokenPath =
    '/public/appointments/token/{token}/updateStatus';

  /**
   * Update Appointment Status By Token.
   *
   * Update Appointment Status by Token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAppointmentStatusByToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentStatusByToken$Response(
    params: {
      token: string;
      body?: UpdateAppointmentStatus;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Appointment>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateAppointmentStatusByTokenPath,
      'patch',
    );
    if (params) {
      rb.path('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Appointment>;
        }),
      );
  }

  /**
   * Update Appointment Status By Token.
   *
   * Update Appointment Status by Token
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAppointmentStatusByToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAppointmentStatusByToken(
    params: {
      token: string;
      body?: UpdateAppointmentStatus;
    },
    context?: HttpContext,
  ): Observable<Appointment> {
    return this.updateAppointmentStatusByToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<Appointment>): Appointment => r.body),
    );
  }

  /** Path part for operation `addAppointment()` */
  static readonly AddAppointmentPath = '/private/appointments';

  /**
   * Add Appointment.
   *
   * Add Appointment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAppointment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAppointment$Response(
    params?: {
      body?: AddAppointment;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Appointment>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddAppointmentPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Appointment>;
        }),
      );
  }

  /**
   * Add Appointment.
   *
   * Add Appointment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAppointment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addAppointment(
    params?: {
      body?: AddAppointment;
    },
    context?: HttpContext,
  ): Observable<Appointment> {
    return this.addAppointment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Appointment>): Appointment => r.body),
    );
  }

  /** Path part for operation `updateOutcome()` */
  static readonly UpdateOutcomePath =
    '/private/appointments/{appointmentId}/outcome';

  /**
   * Update Outcome.
   *
   * Update Outcome
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOutcome()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOutcome$Response(
    params: {
      appointmentId: string;
      body?: UpdateOutcome;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Outcome>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.UpdateOutcomePath,
      'put',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Outcome>;
        }),
      );
  }

  /**
   * Update Outcome.
   *
   * Update Outcome
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOutcome$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOutcome(
    params: {
      appointmentId: string;
      body?: UpdateOutcome;
    },
    context?: HttpContext,
  ): Observable<Outcome> {
    return this.updateOutcome$Response(params, context).pipe(
      map((r: StrictHttpResponse<Outcome>): Outcome => r.body),
    );
  }

  /** Path part for operation `addOutcome()` */
  static readonly AddOutcomePath =
    '/private/appointments/{appointmentId}/outcome';

  /**
   * Add Outcome.
   *
   * Add Outcome
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOutcome()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOutcome$Response(
    params: {
      appointmentId: string;
      body?: AddOutcome;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Outcome>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.AddOutcomePath,
      'post',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Outcome>;
        }),
      );
  }

  /**
   * Add Outcome.
   *
   * Add Outcome
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOutcome$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOutcome(
    params: {
      appointmentId: string;
      body?: AddOutcome;
    },
    context?: HttpContext,
  ): Observable<Outcome> {
    return this.addOutcome$Response(params, context).pipe(
      map((r: StrictHttpResponse<Outcome>): Outcome => r.body),
    );
  }

  /** Path part for operation `browseAvailabilities()` */
  static readonly BrowseAvailabilitiesPath = '/private/availabilities/browse';

  /**
   * Browse Availabilities.
   *
   * Browse Availabilities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseAvailabilities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseAvailabilities$Response(
    params?: {
      body?: BrowseAvailabilities;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListAvailabilities>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseAvailabilitiesPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListAvailabilities>;
        }),
      );
  }

  /**
   * Browse Availabilities.
   *
   * Browse Availabilities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseAvailabilities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseAvailabilities(
    params?: {
      body?: BrowseAvailabilities;
    },
    context?: HttpContext,
  ): Observable<ListAvailabilities> {
    return this.browseAvailabilities$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<ListAvailabilities>): ListAvailabilities =>
          r.body,
      ),
    );
  }

  /** Path part for operation `readConfiguration()` */
  static readonly ReadConfigurationPath = '/public/configuration';

  /**
   * Read Configuration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  readConfiguration$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Configuration>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.ReadConfigurationPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Configuration>;
        }),
      );
  }

  /**
   * Read Configuration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readConfiguration(
    params?: {},
    context?: HttpContext,
  ): Observable<Configuration> {
    return this.readConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Configuration>): Configuration => r.body),
    );
  }

  /** Path part for operation `browseOutcomes()` */
  static readonly BrowseOutcomesPath = '/private/outcomes/browse';

  /**
   * Browse Outcomes.
   *
   * Browse Outcomes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseOutcomes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseOutcomes$Response(
    params?: {
      body?: BrowseOutcomes;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ListOutcomes>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.BrowseOutcomesPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ListOutcomes>;
        }),
      );
  }

  /**
   * Browse Outcomes.
   *
   * Browse Outcomes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseOutcomes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseOutcomes(
    params?: {
      body?: BrowseOutcomes;
    },
    context?: HttpContext,
  ): Observable<ListOutcomes> {
    return this.browseOutcomes$Response(params, context).pipe(
      map((r: StrictHttpResponse<ListOutcomes>): ListOutcomes => r.body),
    );
  }

  /** Path part for operation `getCustomerAppointmentsRecap()` */
  static readonly GetCustomerAppointmentsRecapPath =
    '/private/customers/{customerId}/appointmentsRecap';

  /**
   * Get Customer Appointments Recap.
   *
   * Get Customer Appointments Recap
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAppointmentsRecap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAppointmentsRecap$Response(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<AppointmentsRecap>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetCustomerAppointmentsRecapPath,
      'get',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<AppointmentsRecap>;
        }),
      );
  }

  /**
   * Get Customer Appointments Recap.
   *
   * Get Customer Appointments Recap
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerAppointmentsRecap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAppointmentsRecap(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<AppointmentsRecap> {
    return this.getCustomerAppointmentsRecap$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<AppointmentsRecap>): AppointmentsRecap => r.body,
      ),
    );
  }

  /** Path part for operation `deleteOutcomeImage()` */
  static readonly DeleteOutcomeImagePath =
    '/private/appointments/{appointmentId}/outcome/images/{fileId}';

  /**
   * Delete Outcome Image.
   *
   * Delete Outcome Image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOutcomeImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOutcomeImage$Response(
    params: {
      appointmentId: string;
      fileId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteOutcomeImagePath,
      'delete',
    );
    if (params) {
      rb.path('appointmentId', params.appointmentId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Outcome Image.
   *
   * Delete Outcome Image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOutcomeImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOutcomeImage(
    params: {
      appointmentId: string;
      fileId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteOutcomeImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `deletePetImage()` */
  static readonly DeletePetImagePath =
    '/private/customers/{customerId}/pets/{petId}/image';

  /**
   * Delete Pet Image.
   *
   * Delete Pet Image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePetImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetImage$Response(
    params: {
      customerId: string;
      petId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeletePetImagePath,
      'delete',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('petId', params.petId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Pet Image.
   *
   * Delete Pet Image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePetImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePetImage(
    params: {
      customerId: string;
      petId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deletePetImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `deleteCustomerImage()` */
  static readonly DeleteCustomerImagePath =
    '/private/customers/{customerId}/image';

  /**
   * Delete Customer Image.
   *
   * Delete Customer Image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerImage$Response(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteCustomerImagePath,
      'delete',
    );
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Customer Image.
   *
   * Delete Customer Image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerImage(
    params: {
      customerId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteCustomerImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `deleteOperatorImage()` */
  static readonly DeleteOperatorImagePath =
    '/private/operators/{operatorId}/image';

  /**
   * Delete Operator Image.
   *
   * Delete Operator Image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOperatorImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOperatorImage$Response(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.DeleteOperatorImagePath,
      'delete',
    );
    if (params) {
      rb.path('operatorId', params.operatorId, {});
    }

    return this.http
      .request(rb.build({ responseType: 'text', accept: '*/*', context }))
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({
            body: undefined,
          }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Delete Operator Image.
   *
   * Delete Operator Image
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOperatorImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOperatorImage(
    params: {
      operatorId: string;
    },
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteOperatorImage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `getShortUrls()` */
  static readonly GetShortUrlsPath = '/public/shortUrls/{shortUrlToken}';

  /**
   * Get ShortUrl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getShortUrls()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortUrls$Response(
    params: {
      shortUrlToken: string;
    },
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ShortUrl>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ApiService.GetShortUrlsPath,
      'get',
    );
    if (params) {
      rb.path('shortUrlToken', params.shortUrlToken, {});
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<ShortUrl>;
        }),
      );
  }

  /**
   * Get ShortUrl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getShortUrls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getShortUrls(
    params: {
      shortUrlToken: string;
    },
    context?: HttpContext,
  ): Observable<ShortUrl> {
    return this.getShortUrls$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShortUrl>): ShortUrl => r.body),
    );
  }
}

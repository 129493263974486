import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  EnvironmentInjector,
  Renderer2,
  createComponent,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, fromEvent } from 'rxjs';
import { PasswordStrengthMeterComponent } from './password-strength-meter.component';

@Directive({
  selector: '[appPasswordStrengthMeter]',
})
export class PasswordStrengthMeterDirective implements AfterViewInit {
  #inputEl: HTMLElement | undefined;
  #inputGroupEl: HTMLElement | undefined;

  private readonly elRef: ElementRef = inject(ElementRef);
  private readonly renderer2: Renderer2 = inject(Renderer2);
  private readonly injector: EnvironmentInjector = inject(EnvironmentInjector);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  ngAfterViewInit(): void {
    this.#inputEl = this.elRef.nativeElement.querySelector('input');
    this.#inputGroupEl =
      this.elRef.nativeElement.querySelector('nz-input-group');

    const element = this.#inputGroupEl ?? this.#inputEl;
    if (element && this.#inputEl) {
      const div: HTMLElement = this.renderer2.createElement('div');
      this.renderer2.insertBefore(
        element.parentElement,
        div,
        element.nextElementSibling,
      );
      const componentRef = createComponent(PasswordStrengthMeterComponent, {
        environmentInjector: this.injector,
        hostElement: div,
      });
      componentRef.instance.passwordToCheck = '';
      fromEvent(this.#inputEl, 'input')
        .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
        .subscribe((event) => {
          const target: HTMLInputElement | null =
            event.target instanceof HTMLInputElement ? event.target : null;
          componentRef.instance.passwordToCheck = target?.value || '';
        });
    }
  }
}

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Company } from '@generated/models';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs';
import { selectCompany } from '../store/selectors/company.selector';

export const tenantInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);

  return store.select(selectCompany).pipe(
    take(1),
    switchMap((company: Company | null) => {
      if (company?.activities.length) {
        const modifiedReq = req.clone({
          headers: req.headers.set(
            'x-activity-id',
            (company.activities[0] as any).id,
          ),
        });
        return next(modifiedReq);
      }
      return next(req);
    }),
  );
};

/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

interface TusConfiguration {
  'tus.endpoint': string;
}
export class Configuration implements TusConfiguration {
  'tus.endpoint': string;

  constructor(source: TusConfiguration) {
    Object.assign(this, source);
  }
}

async function bootstrap() {
  const response = await fetch('/api/public/configuration');
  const payload = await response.json();
  await platformBrowserDynamic([
    {
      provide: Configuration,
      useValue: new Configuration(payload),
    },
  ]).bootstrapModule(AppModule);
}

bootstrap().catch((err) => console.error(err));

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));

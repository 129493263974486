import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-gallery',
  template: `
    <p-galleria
      [value]="value"
      [showThumbnails]="true"
      [numVisible]="numVisible"
      [(activeIndex)]="activeIndex"
    >
      <ng-template pTemplate="item" let-item>
        <div
          class="flex relative justify-content-center align-items-center w-full"
          style="height: 420px; max-height: 420px;"
          *ngIf="item"
        >
          <div class="absolute right-0 top-0 p-4">
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              class="p-button-danger"
              (click)="onDelete(item)"
              type="button"
            ></button>
          </div>
          <div
            class="w-full h-full flex justify-content-center"
            style="background-color: #191919;"
          >
            <p-image
              [src]="item.url"
              styleClass="w-fit h-full"
              imageClass="w-full h-full"
              [imageStyle]="{ 'object-fit': 'contain' }"
              [preview]="true"
              (click)="$event.preventDefault()"
            />
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div
          class="grid grid-nogutter justify-content-center"
          style="width: 100px; height: 100px;"
          *ngIf="item"
        >
          <img
            [src]="item.url"
            class="w-full h-full"
            style="object-fit: contain;"
          />
        </div>
      </ng-template>
    </p-galleria>
  `,
  // TODO: cercare alternativa per non forzare l'override della classe p-items-hidden con ng-deep
  styles: [
    `
      :host ::ng-deep {
        /* .p-items-hidden .p-galleria-thumbnail-item {
          visibility: visible !important;
        } */

        .p-galleria-thumbnail-item {
          flex: 1 0 20% !important;
        }
      }
    `,
  ],
})
export class GalleryComponent implements OnChanges {
  readonly maxNumVisible = 5;
  numVisible: number = this.maxNumVisible;
  private deleteAction: boolean = false;

  value: { id: string; url: string; uploaded: boolean }[] = [];
  @Input() set images(value: { id: string; url: string; uploaded: boolean }[]) {
    this.value = value;
    this.numVisible = Math.min(value.length, this.maxNumVisible);
  }
  @Output() delete = new EventEmitter<{
    id: string;
    url: string;
    uploaded: boolean;
  }>();
  activeIndex = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['images'] &&
      this.deleteAction === false &&
      !changes['images'].firstChange
    ) {
      this.activeIndex = changes['images'].currentValue.length - 1;
    }
    this.deleteAction = false;
  }

  onDelete(item: { id: string; url: string; uploaded: boolean }) {
    this.activeIndex = this.activeIndex === 0 ? 0 : this.activeIndex - 1;
    this.deleteAction = true;
    this.delete.emit(item);
  }
}

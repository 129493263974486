import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TimeRange } from '@app/app/shared/components/time-range/interfaces/time-range.interface';

export function validateAvailableDays(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const availableDays = control.value;
    const atLeastOneRestriction = (
      Object.values(availableDays || {}) as TimeRange[][]
    ).some((v: TimeRange[]) => !!v.length);
    return !atLeastOneRestriction
      ? {
          required: true,
        }
      : null;
  };
}

import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { toastMessages } from '@app/app/core/store/selectors/layout.selectors';
import { Store } from '@ngrx/store';
import { Message, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toast',
  template: ` <p-toast /> `,
  styles: [],
  providers: [MessageService],
})
export class ToastComponent {
  private readonly store = inject(Store);
  private readonly messageService = inject(MessageService);

  toastMessages$: Observable<Message[]> = this.store.select(toastMessages);

  constructor() {
    this.toastMessages$
      .pipe(takeUntilDestroyed())
      .subscribe((toast: Message[]) => {
        this.messageService.addAll(toast);
      });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: '[app-topbar-gift]',
  template: `
    <li class="layout-submenu-header">
      <h1>Deals</h1>
    </li>

    <li class="deals">
      <ul>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-sapphire.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Sapphire</p>
            <span>Angular</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-roma.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Roma</p>
            <span>Minimalism</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-babylon.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Babylon</p>
            <span>Powerful</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
      </ul>
      <ul>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-harmony.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Harmony</p>
            <span>USWDS</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-prestige.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Prestige</p>
            <span>Elegancy</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
        <li>
          <img
            src="/assets/images/topbar/deal-icon-ultima.png"
            alt="mirage-layout"
            width="35"
          />
          <div class="menu-text">
            <p>Ultima</p>
            <span>Material</span>
          </div>
          <i class="pi pi-angle-right"></i>
        </li>
      </ul>
    </li>
  `,
  styles: [],
})
export class TopbarGiftComponent {}

import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TimeRange } from '../time-range/interfaces/time-range.interface';

@Component({
  selector: 'app-availability-rule-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="grid grid-nogutter row-gap-3">
      <ng-container
        *ngFor="let control of rules.controls; index as i; last as last"
      >
        <div class="col-12 flex">
          <div class="flex-1">
            <app-time-range [formControl]="rules.controls[i]" />
          </div>
          <div class="pl-3">
            <p-button
              icon="pi pi-trash"
              [disabled]="_disabled"
              styleClass="p-button-danger"
              (click)="remove(i)"
            />
            <p-button
              icon="pi pi-plus"
              styleClass="ml-3"
              [ngStyle]="{ visibility: last ? 'visible' : 'hidden' }"
              [disabled]="!canAdd(i)"
              (click)="add(i)"
            />
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvailabilityRuleInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AvailabilityRuleInputComponent),
      multi: true,
    },
  ],
})
export class AvailabilityRuleInputComponent
  implements ControlValueAccessor, Validator
{
  onChange: any = () => {};
  onTouch: any = () => {};
  onValidationChange: any = () => {};

  rules = new FormArray<FormControl>([]);

  sortFn = (a: TimeRange, b: TimeRange) => {
    const defaultValue = '24:00';
    return (
      (a.from || defaultValue).localeCompare(b.from || defaultValue) ||
      (a.to || defaultValue).localeCompare(b.to || defaultValue)
    );
  };

  _value: TimeRange[] = [];
  set value(value: TimeRange[]) {
    this._value = value;
    this.onChange(value);
    this.onTouch();
    this.onValidationChange();
  }

  _disabled: boolean = false;
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    disabled ? this.rules.disable() : this.rules.enable();
  }

  constructor() {
    this.rules.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.value = value.filter((v) => v.from || v.to);
    });
  }

  writeValue(value: TimeRange[] | null): void {
    this.rules.clear();
    value?.length
      ? value?.forEach((val) => {
          this.rules.push(new FormControl(val), { emitEvent: false });
        })
      : this.rules.push(new FormControl({ from: null, to: null }));
    this.rules.patchValue(this.rules.value.sort(this.sortFn));
    this._value = value || [];
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  canAdd(index: number): boolean {
    const control = this.rules.controls[index];
    return (
      !this._disabled && control.valid && control.value.from && control.value.to
    );
  }

  add(index: number): void {
    if (this.canAdd(index)) {
      this.rules.push(
        new FormControl({
          from: null,
          to: null,
        }),
        { emitEvent: false }
      );
      this.rules.patchValue(this.rules.value.sort(this.sortFn));
    }
  }

  remove(index: number): void {
    if (!this._disabled) {
      if (this.rules.controls.length === 1) {
        this.rules.controls[index].setValue({ from: null, to: null });
      } else {
        this.rules.removeAt(index);
      }
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.rules.valid
      ? null
      : this.rules.controls.reduce((errors: Record<string, any[]>, control) => {
          Object.keys(control.errors || []).forEach((error) => {
            errors[error] = [...(errors[error] ?? []), control.errors?.[error]];
          });
          return errors;
        }, {});
  }
}

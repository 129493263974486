import { Pipe, PipeTransform } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PetType } from '@generated/models/pet-type';

@Pipe({ name: 'petTypeIcon' })
export class PetTypeIconPipe implements PipeTransform {
  transform(petType: PetType | null): IconProp {
    switch (petType) {
      case PetType.Cat:
        return ['fas', 'cat'];
      case PetType.Dog:
        return ['fas', 'dog'];
      default:
        return ['fas', 'paw'];
    }
  }
}

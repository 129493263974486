import { Pipe, PipeTransform } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Pipe({
  name: 'trendIcon',
})
export class TrendIconPipe implements PipeTransform {
  transform(trend: -1 | 1 | 0): IconProp;
  transform(trend: -1 | 1 | 0, type: 'icon'): IconProp;
  transform(trend: -1 | 1 | 0, type: 'color'): string;
  transform(trend: -1 | 1 | 0, type?: 'icon' | 'color'): any {
    type = type || 'icon';
    const icons = {
      [-1]: { icon: ['fas', 'arrow-trend-down'], color: 'red' },
      [1]: { icon: ['fas', 'arrow-trend-up'], color: 'green' },
      [0]: { icon: ['fas', 'arrow-right-long'], color: 'auto' },
    };
    return icons[trend][type];
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrimeModule } from '../../shared/prime.module';
import { SharedModule } from '../../shared/shared.module';
import { BreadcrumbComponent } from './app/components/breadcrumb.component';
import { FooterComponent } from './app/components/footer.component';
import { LayoutAppComponent } from './app/components/layout-app.component';
import { LoginLayoutAppComponent } from './app/components/login-layout-app.component';
import { PrimaryMenuItemComponent } from './app/components/primary-menu/primary-menu-item.component';
import { PrimaryMenuComponent } from './app/components/primary-menu/primary-menu.component';
import { RightPanelComponent } from './app/components/right-panel/right-panel.component';
import { ToastComponent } from './app/components/toast/toast.component';
import { MegamenuComponent } from './app/components/topbar/megamenu.component';
import { TopbarCalendarComponent } from './app/components/topbar/topbar-calendar.component';
import { TopbarGiftComponent } from './app/components/topbar/topbar-gift.component';
import { TopbarMessageComponent } from './app/components/topbar/topbar-message.component';
import { TopbarProfileMobileComponent } from './app/components/topbar/topbar-profile-mobile.component';
import { TopbarProfileComponent } from './app/components/topbar/topbar-profile.component';
import { TopbarComponent } from './app/components/topbar/topbar.component';

@NgModule({
  declarations: [
    LayoutAppComponent,
    PrimaryMenuComponent,
    PrimaryMenuItemComponent,
    TopbarComponent,
    MegamenuComponent,
    TopbarCalendarComponent,
    TopbarMessageComponent,
    TopbarGiftComponent,
    TopbarProfileComponent,
    TopbarProfileMobileComponent,
    RightPanelComponent,
    BreadcrumbComponent,
    FooterComponent,
    ToastComponent,
    LoginLayoutAppComponent,
  ],
  imports: [CommonModule, SharedModule, RouterModule, PrimeModule],
})
export class LayoutModule {}

import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Configuration } from '@app/main';
import Uppy, { Restrictions, UploadResult, UppyFile } from '@uppy/core';
import Tus from '@uppy/tus';
import { Subject, takeUntil } from 'rxjs';
import { UploadFileButtonContentDirective } from './upload-file-button-content.directive';
import { UploadFileMeta } from './upload-file-meta.interface';

@Component({
  selector: 'app-upload-file',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="w-max uploadFileButton">
      <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
    </div>
    <uppy-dashboard
      [uppy]="uppy"
      [props]="{
        hideUploadButton: true,
        width: 'auto',
        inline: false,
        trigger: '.uploadFileButton',
      }"
    ></uppy-dashboard>
  `,
  styles: [],
})
export class UploadFileComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroyed$: Subject<void> = new Subject();

  @Input({ required: true }) uploadFiles: Subject<UploadFileMeta> | undefined;
  @Input() removeFile: Subject<string> | undefined;
  @Input() set restrictions(restrictions: Restrictions) {
    this.uppy.setOptions({
      restrictions,
    });
  }
  @Output() fileChanged: EventEmitter<UppyFile[]> = new EventEmitter<
    UppyFile[]
  >();
  @Output() uploadStarted: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadCompleted: EventEmitter<string> = new EventEmitter<string>();

  @ContentChild(UploadFileButtonContentDirective)
  content!: UploadFileButtonContentDirective;

  readonly uppy = new Uppy({
    autoProceed: false,
  });

  constructor(private readonly configuration: Configuration) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['uploadFiles']) {
      this.uploadFiles
        ?.pipe(takeUntil(this.destroyed$))
        .subscribe((meta: UploadFileMeta) => this.upload(meta));
    }
    if (changes['removeFile']) {
      this.removeFile
        ?.pipe(takeUntil(this.destroyed$))
        .subscribe((fileId: string) => {
          this.remove(fileId);
        });
    }
  }

  ngOnInit(): void {
    this.uppy.use(Tus, {
      endpoint: this.configuration['tus.endpoint'],
      storeFingerprintForResuming: false,
    });

    this.uppy.on('complete', (result: UploadResult & { uploadID?: string }) => {
      this.uploadCompleted.emit(result.uploadID);
      this.uppy.cancelAll();
      this.fileChanged.emit([]);
    });

    this.uppy.on('dashboard:modal-closed', () => {
      const files: UppyFile[] = this.uppy.getFiles();
      this.fileChanged.emit(files);
    });

    this.uppy.on('upload', (data: { id: string; fileIDs: string[] }) => {
      this.uploadStarted.emit(data.id);
    });
  }

  private upload(meta: UploadFileMeta): void {
    if (this.uppy.getFiles().length > 0) {
      this.uppy.setMeta(meta);
      this.uppy.upload();
    }
  }

  private remove(fileId: string): void {
    this.uppy.removeFile(fileId);
    const files: UppyFile[] = this.uppy.getFiles();
    this.fileChanged.emit(files);
  }
}

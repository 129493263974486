import { Component } from '@angular/core';

@Component({
  selector: 'app-login-layout',
  template: `
    <div class="flex shadow-3	w-full bg-white justify-content-center h-6rem">
      <img class="p-2 w-15rem" src="/assets/logo-romeo.svg" />
    </div>

    <router-outlet></router-outlet>
  `,
})
export class LoginLayoutAppComponent {}

/* tslint:disable */
/* eslint-disable */
export enum PetBodyPart {
  Paws = 'paws',
  Legs = 'legs',
  Tail = 'tail',
  Ears = 'ears',
  Back = 'back',
  Belly = 'belly',
  Front = 'front',
  Rear = 'rear',
}

import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormRecord,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { roundToNearestMinutes } from 'date-fns';
import { TimeRange } from './interfaces/time-range.interface';

@Component({
  selector: 'app-time-range',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="grid grid-nogutter">
      <div class="col-6 pr-2">
        <p-calendar
          [formControl]="formRecord.controls['from']"
          [stepMinute]="TIME_STEP"
          [timeOnly]="true"
          (onFocus)="setDefaultDate('from')"
          (onBlur)="adjustTime('from')"
        />
      </div>
      <div class="col-6 pl-2">
        <p-calendar
          [formControl]="formRecord.controls['to']"
          [stepMinute]="TIME_STEP"
          [timeOnly]="true"
          (onFocus)="setDefaultDate('to')"
          (onBlur)="adjustTime('to')"
        />
      </div>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeRangeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimeRangeComponent),
      multi: true,
    },
  ],
})
export class TimeRangeComponent implements ControlValueAccessor, Validator {
  readonly TIME_STEP = 15;

  @Input() allowEmpty: boolean = true;

  formRecord = new FormRecord({
    from: new FormControl<Date | null>(null, { nonNullable: true }),
    to: new FormControl<Date | null>(null, { nonNullable: true }),
  });

  onChange: any = () => {};
  onTouch: any = () => {};
  onValidationChange: any = () => {};

  _value: TimeRange = { from: null, to: null };
  set value(value: TimeRange) {
    this._value = value;
    this.onChange(value);
    this.onTouch();
    this.onValidationChange();
  }

  set disabled(disabled: boolean) {
    disabled ? this.formRecord.disable() : this.formRecord.enable();
  }

  constructor(@Inject(LOCALE_ID) private readonly locale: string) {
    this.formRecord.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.value = {
          from: value['from']
            ? formatDate(value['from'], 'HH:mm', this.locale)
            : null,
          to: value['to']
            ? formatDate(value['to'], 'HH:mm', this.locale)
            : null,
        };
      });
  }

  setDefaultDate(inputName: 'from' | 'to'): void {
    const value = this.formRecord.value;
    if (value[inputName] === null) {
      this.formRecord
        .get(inputName)
        ?.setValue(
          roundToNearestMinutes(new Date(), { nearestTo: this.TIME_STEP })
        );
    }
  }

  adjustTime(inputName: 'from' | 'to'): void {
    const value = this.formRecord.value;
    if (value[inputName]) {
      this.formRecord.get(inputName)?.setValue(
        roundToNearestMinutes(value[inputName]!, {
          nearestTo: this.TIME_STEP,
        })
      );
    }
  }

  writeValue(value: TimeRange): void {
    const val = {
      from: value.from ?? null,
      to: value.to ?? null,
    };
    this.formRecord.setValue({
      from: this.timeToDate(val.from),
      to: this.timeToDate(val.to),
    });
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let valid = true;
    if (!this.allowEmpty) {
      valid = valid && control.value.from && control.value.to;
    }
    valid = control.value.from <= control.value.to;
    return valid ? null : { rangeNotValid: { value: control.value } };
  }

  private timeToDate(time: string | null): Date | null {
    const now = new Date();
    const [hours, minutes] = time?.split(':') || '';
    if (hours && minutes) {
      now.setHours(parseInt(hours, 10)), now.setMinutes(parseInt(minutes, 10));
      return now;
    }
    return null;
  }
}

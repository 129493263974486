import {
  Component,
  DoCheck,
  Injector,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  Validators,
} from '@angular/forms';
import { formatISO, parseISO } from 'date-fns';

@Component({
  selector: 'app-time-input',
  template: ` <p-calendar [formControl]="formControl" [timeOnly]="true" /> `,
})
export class TimeInputComponent
  implements OnInit, DoCheck, ControlValueAccessor
{
  _refDate: Date = new Date();
  @Input() set refDate(value: string | Date) {
    if (value) {
      this._refDate = typeof value === 'string' ? parseISO(value) : value;
    }
  }
  @Input() format: 'date' | 'string' = 'string';

  formControl = new FormControl<Date | null>(null);

  onChange: any = () => {};
  onTouch: any = () => {};

  _value: string | Date | null = null;
  set value(value: string | Date | null) {
    this._value = value;
    this.onChange(value);
    this.onTouch();
  }

  set disabled(disabled: boolean) {
    disabled ? this.formControl.disable() : this.formControl.enable();
  }

  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private readonly injector: Injector,
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);

    this.formControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value: Date | null) => {
        const val: Date = new Date(this._refDate);
        if (value) {
          val.setMinutes(value.getMinutes());
          val.setHours(value.getHours());
          val.setSeconds(0);
        }
        this.value = value
          ? this.format === 'string'
            ? formatISO(val)
            : val
          : null;
      });
  }

  ngOnInit(): void {
    let control: FormControl | null = null;
    if (this.ngControl) {
      if (this.ngControl instanceof FormControlName) {
        control = this.injector
          .get(FormGroupDirective)
          .getControl(this.ngControl);
      } else {
        control = (this.ngControl as FormControlDirective).form;
      }
    }
    if (control?.hasValidator(Validators.required)) {
      this.formControl.addValidators(Validators.required);
    }
  }

  ngDoCheck(): void {
    if (this.ngControl?.dirty) {
      this.formControl.markAsDirty();
    } else {
      this.formControl.markAsPristine();
    }
  }

  writeValue(value: string | Date | null): void {
    let val: Date | null = value
      ? typeof value === 'string'
        ? parseISO(value)
        : null
      : null;
    this.formControl.setValue(val, { emitEvent: false });
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

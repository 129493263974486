import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Back, Forward, Go, GoByUrl } from '../actions/router.actions';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
  ) {}

  navigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(Go),
        map((action) => action.payload),
        tap(({ path, query, extras }) => {
          this.router.navigate(path, { queryParams: { ...query }, ...extras });
        }),
      );
    },
    { dispatch: false },
  );

  navigateByUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(GoByUrl),
        tap((action) => this.router.navigateByUrl(action.url)),
      );
    },
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(Back),
        tap(() => this.location.back()),
      );
    },
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(Forward),
        tap(() => this.location.forward()),
      );
    },
    { dispatch: false },
  );
}

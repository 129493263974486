import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AvailabilityRuleInputComponent } from '@app/app/shared/components/availability-rule-input/availability-rule-input.component';
import { TimeRangeComponent } from '@app/app/shared/components/time-range/time-range.component';
import { GetFormArrayPipe } from '@app/app/shared/pipes/getFormArray.pipe';
import { PetTypeIconPipe } from '@app/app/shared/pipes/pet-type-icon.pipe';
import { PrimeModule } from '@app/app/shared/prime.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppointmentStateSelectorComponent } from './components/appointment-state-selector/appointment-state-selector.component';
import { AvatarInputComponent } from './components/avatar-input/avatar-input.component';
import { BusinessHoursInputComponent } from './components/business-hours-input/business-hours-input.component';
import { CheckboxBlockComponent } from './components/checbox-block/checkbox-block.component';
import { ClosingDaysInputComponent } from './components/closing-days-input/closing-days-input.component';
import { CustomerAvatarComponent } from './components/customer-avatar/customer-avatar.component';
import { DurationInputComponent } from './components/duration-input/duration-input.component';
import { GalleryInputComponent } from './components/gallery-input/gallery-input.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OperatorAvatarComponent } from './components/operator-avatar/operator-avatar.component';
import { PasswordStrengthMeterComponent } from './components/password-strength-meter/password-strength-meter.component';
import { PasswordStrengthMeterDirective } from './components/password-strength-meter/password-strength-meter.directive';
import { PetAvatarComponent } from './components/pet-avatar/pet-avatar.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { UploadFileButtonContentDirective } from './components/upload-file/upload-file-button-content.directive';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { DurationPipe } from './pipes/duration.pipe';
import { StoragePathPipe } from './pipes/storage-path.pipe';
import { TrendIconPipe } from './pipes/trend-icon.pipe';
import { UppyModule } from './uppy.module';

@NgModule({
  declarations: [
    CheckboxBlockComponent,
    ClickOutsideDirective,
    AvailabilityRuleInputComponent,
    TimeRangeComponent,
    PetTypeIconPipe,
    GetFormArrayPipe,
    DurationPipe,
    AppointmentStateSelectorComponent,
    GalleryComponent,
    TimeInputComponent,
    DurationInputComponent,
    BusinessHoursInputComponent,
    ClosingDaysInputComponent,
    UploadFileComponent,
    UploadFileButtonContentDirective,
    AvatarInputComponent,
    StoragePathPipe,
    PetAvatarComponent,
    CustomerAvatarComponent,
    OperatorAvatarComponent,
    GalleryInputComponent,
    TrendIconPipe,
    PasswordStrengthMeterComponent,
    PasswordStrengthMeterDirective,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    PrimeModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    UppyModule,
  ],
  exports: [
    CheckboxBlockComponent,
    ClickOutsideDirective,
    AvailabilityRuleInputComponent,
    TimeRangeComponent,
    PetTypeIconPipe,
    GetFormArrayPipe,
    DurationPipe,
    AppointmentStateSelectorComponent,
    GalleryComponent,
    TimeInputComponent,
    DurationInputComponent,
    BusinessHoursInputComponent,
    ClosingDaysInputComponent,
    UploadFileComponent,
    UploadFileButtonContentDirective,
    AvatarInputComponent,
    StoragePathPipe,
    PetAvatarComponent,
    CustomerAvatarComponent,
    OperatorAvatarComponent,
    GalleryInputComponent,
    TrendIconPipe,
    PasswordStrengthMeterComponent,
    PasswordStrengthMeterDirective,
    LoaderComponent,
  ],
})
export class SharedModule {}

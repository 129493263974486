import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, throwError } from 'rxjs';
import { ShowToast } from '../store/actions/layout.action';

export const errorCatchingInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  return next(req).pipe(
    catchError((e: HttpResponse<unknown>) => {
      store.dispatch(
        ShowToast({
          message: {
            severity: 'error',
            summary: 'Errore',
            detail: `Si è verificato un errore (${e.status})`,
          },
        }),
      );
      return throwError(() => e);
    }),
  );
};

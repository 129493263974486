import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <div class="layout-footer">
      <div class="logo-text">
        <img
          src="/assets/images/topbar/deal-icon-roma.png"
          alt="mirage-layout"
        />
        <div class="text">
          <h1>Romeo {{ version }}</h1>
          <span>Fullstackagency</span>
        </div>
      </div>
      <div class="icons">
        <!--
        <div class="icon icon-hastag">
          <i class="pi pi-home"></i>
        </div>
        <div class="icon icon-twitter">
          <i class="pi pi-globe"></i>
        </div>
        <div class="icon icon-prime">
          <i class="pi pi-bookmark"></i>
        </div>
        -->
      </div>
    </div>
  `,
  styles: [],
})
export class FooterComponent {
  @Input() version: string = '';
}

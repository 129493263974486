import { createReducer, on } from '@ngrx/store';
import {
  ReadCompany,
  ReadCompanyFailed,
  ReadCompanySuccess,
} from '../actions/company.action';
import { CompanyState } from '../interfaces/company.interface';

const initialCompanyState: CompanyState = {
  loading: false,
  company: null,
};

export const companyStateKey = 'company';

export const companyReducer = createReducer(
  initialCompanyState,
  on(ReadCompany, (state) => ({
    ...state,
    company: null,
  })),
  on(ReadCompanySuccess, (state, action) => ({
    ...state,
    company: action.company,
  })),
  on(ReadCompanyFailed, (state) => ({
    ...state,
    company: null,
  })),
);

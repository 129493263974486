/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Company } from '../models/company';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readCompany()` */
  static readonly ReadCompanyPath = '/private/companies';

  /**
   * Read Company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  readCompany$Response(
    params?: {},
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Company>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CompanyService.ReadCompanyPath,
      'get',
    );
    if (params) {
    }

    return this.http
      .request(
        rb.build({ responseType: 'json', accept: 'application/json', context }),
      )
      .pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Company>;
        }),
      );
  }

  /**
   * Read Company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readCompany(params?: {}, context?: HttpContext): Observable<Company> {
    return this.readCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<Company>): Company => r.body),
    );
  }
}

import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TimeRange } from '@app/app/shared/components/time-range/interfaces/time-range.interface';
import { validateAvailableDays } from '@app/app/shared/validators/availability-rules.validators';
import { DayName } from '@generated/models';
import { distinctUntilChanged, startWith } from 'rxjs';

@Component({
  selector: 'app-business-hours-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="p-fluid row-gap-4 formgrid grid" [formGroup]="formGroup">
      <div class="field col-6">
        <p-selectButton
          [options]="availabilityOptions"
          formControlName="alwaysAvailable"
        ></p-selectButton>
      </div>
      <div class="col-6"></div>
      <ng-container formGroupName="availableDays">
        <div class="field col-12 md:col-6">Lunedì</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Monday" />
        </div>
        <div class="field col-12 md:col-6">Martedì</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Tuesday" />
        </div>
        <div class="field col-12 md:col-6">Mercoledì</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Wednesday" />
        </div>
        <div class="field col-12 md:col-6">Giovedì</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Thursday" />
        </div>
        <div class="field col-12 md:col-6">Venerdì</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Friday" />
        </div>
        <div class="field col-12 md:col-6">Sabato</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Saturday" />
        </div>
        <div class="field col-12 md:col-6">Domenica</div>
        <div class="field col-12 md:col-6">
          <app-availability-rule-input [formControlName]="DayName.Sunday" />
        </div>
      </ng-container>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BusinessHoursInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BusinessHoursInputComponent),
      multi: true,
    },
  ],
})
export class BusinessHoursInputComponent
  implements ControlValueAccessor, Validator
{
  protected readonly DayName = DayName;

  availabilityOptions = [
    {
      label: 'Sempre disponibile',
      value: true,
    },
    {
      label: 'Parzialmente disponibile',
      value: false,
    },
  ];

  formGroup: FormGroup = this.fb.nonNullable.group({
    alwaysAvailable: this.fb.nonNullable.control<boolean>(true),
    availableDays: this.fb.nonNullable.group<Record<DayName, FormControl>>(
      {
        [DayName.Monday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Tuesday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Wednesday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Thursday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Friday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Saturday]: this.fb.nonNullable.control<TimeRange[]>([]),
        [DayName.Sunday]: this.fb.nonNullable.control<TimeRange[]>([]),
      },
      { validators: [validateAvailableDays()] },
    ),
  });

  onChange: any = () => {};
  onTouch: any = () => {};
  onValidationChange: any = () => {};

  _value: { day: DayName; from: string; to: string }[] = [];
  set value(value: { day: DayName; from: string; to: string }[]) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
      this.onTouch();
    }
  }

  _disabled: boolean = false;
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (disabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
      this.formGroup.get('alwaysAvailable')?.value
        ? this.formGroup.get('availableDays')?.disable()
        : this.formGroup.get('availableDays')?.enable();
    }
  }

  constructor(private readonly fb: FormBuilder) {
    this.formGroup
      .get('alwaysAvailable')
      ?.valueChanges.pipe(
        takeUntilDestroyed(),
        startWith(true),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        value
          ? this.formGroup.get('availableDays')?.disable()
          : this.formGroup.get('availableDays')?.enable();
      });

    this.formGroup
      .get('availableDays')
      ?.valueChanges.pipe(takeUntilDestroyed(), distinctUntilChanged())
      .subscribe((availableDays) => {
        this.value = availableDays
          ? (Object.keys(availableDays) as DayName[])
              .map((day: DayName) =>
                availableDays[day]!.map((rule: TimeRange) => ({
                  from: rule.from!,
                  to: rule.to!,
                  day,
                })),
              )
              .flat()
          : [];
      });
  }

  writeValue(value: { day: DayName; from: string; to: string }[]): void {
    this.formGroup.patchValue({
      alwaysAvailable: value.length === 0,
      availableDays: value.reduce(
        (availability, rule: { day: DayName; from: string; to: string }) => {
          availability[rule.day] = [
            ...(availability[rule.day] ?? []),
            { from: rule.from, to: rule.to },
          ];
          return availability;
        },
        {} as Record<Partial<DayName>, TimeRange[]>,
      ),
    });
    this.value = value;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid
      ? null
      : {
          required: true,
        };
  }
}

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { errorCatchingInterceptor } from './interceptors/error-catching.interceptor';
import { loadingInterceptor } from './interceptors/loading.interceptor';
import { tenantInterceptor } from './interceptors/tenant.interceptor';
import { LayoutModule } from './layout/layout.module';
import { RomeoStoreModule } from './store/romeo.store.module';

@NgModule({
  declarations: [],
  imports: [LayoutModule, RomeoStoreModule],
  providers: [
    provideHttpClient(
      withInterceptors([
        errorCatchingInterceptor,
        loadingInterceptor,
        tenantInterceptor,
      ])
    ),
  ],
})
export class CoreModule {}

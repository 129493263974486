import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UppyFile } from '@uppy/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  shareReplay,
} from 'rxjs';
import { storagePath } from '../../helpers/storage-path.helper';
import { UploadFileMeta } from '../upload-file/upload-file-meta.interface';
import { GalleryImage } from './gallery-image.interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-gallery-input',
  template: `
    <div class="flex flex-column gap-2">
      <app-upload-file
        [uploadFiles]="uploadFiles"
        [removeFile]="removeFile$"
        [restrictions]="{ allowedFileTypes: ['image/*'] }"
        (fileChanged)="onUploadFileChanged($event)"
        (uploadStarted)="uploadStarted.emit($event)"
        (uploadCompleted)="uploadCompleted.emit($event)"
      >
        <ng-template appUploadFileButton>
          <p-button
            icon="pi pi-image"
            label="Aggiungi foto"
            [badge]="
              (tmpImagesObs$ | async)?.length
                ? (tmpImagesObs$ | async)?.length?.toString() || ''
                : ''
            "
            badgeClass="p-badge-danger"
          >
          </p-button>
        </ng-template>
      </app-upload-file>
      <app-gallery
        [images]="(images$ | async) || []"
        (delete)="onDelete($event)"
      ></app-gallery>
    </div>
  `,
  styles: [],
})
export class GalleryInputComponent {
  removeFile$: Subject<string> = new Subject();
  readonly tmpImages$: BehaviorSubject<GalleryImage[]> = new BehaviorSubject<
    GalleryImage[]
  >([]);
  readonly tmpImagesObs$: Observable<GalleryImage[]> = this.tmpImages$.pipe(
    shareReplay()
  );
  readonly currentImages$: BehaviorSubject<GalleryImage[]> =
    new BehaviorSubject<GalleryImage[]>([]);

  readonly images$: Observable<
    { id: string; url: string; uploaded: boolean }[]
  > = combineLatest({
    tmpImages: this.tmpImagesObs$,
    currentImages: this.currentImages$,
  }).pipe(
    map(({ tmpImages, currentImages }) => [...currentImages, ...tmpImages])
  );

  @Input({ required: true }) uploadFiles: Subject<UploadFileMeta> | undefined;
  @Input() set images(images: string[]) {
    this.currentImages$.next(
      images.map((image) => ({
        id: image,
        url: storagePath(image),
        uploaded: true,
      }))
    );
  }

  @Output() uploadStarted: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadCompleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();

  onUploadFileChanged(files: UppyFile[]) {
    const tmpFiles: GalleryImage[] = files
      .map((file: UppyFile) =>
        file.preview
          ? { id: file.id, uploaded: false, url: file.preview }
          : null
      )
      .filter((v): v is { id: string; uploaded: boolean; url: string } => !!v);
    this.tmpImages$.next(tmpFiles);
  }

  onDelete(image: GalleryImage): void {
    if (!image.uploaded) {
      this.removeFile$.next(image.id);
    } else {
      this.delete.emit(image.id);
    }
  }
}

import { createAction, props } from '@ngrx/store';
import { Message } from 'primeng/api';

export enum LayoutActionTypes {
  SIDE_BAR = '[Layout] SidebarClick',
  MENU_TOGGLE = '[Layout] ToggleMenu',
  MENU = '[Layout] MenuButtonClick',
  MENU_ITEM = '[Layout] MenuItemButtonClick',
  LAYOUT = '[Layout] LayoutClick',
  MEGA_MENU_ITEM = '[Layout] OnMegaMenuItemClick',
  MEGA_MENU = '[Layout] MegaMenuClick',
  MEGA_MENU_MOBILE_ITEM = '[Layout] MegaMenuMobileButtonClick',
  TOP_BAR_ITEM = '[Layout] TopbarItemClick',
  TOP_BAR_MOBILE_ITEM = '[Layout] TopbarMobileMenuButtonClick',
  RIGHT_PANEL = '[Layout] RightPanelClick',
  RIGHT_PANEL_ITEM = '[Layout] RightPanelButtonClick',
  RIGHT_PANEL_CLOSE = '[Layout] RightPanelClose',
  RIPPLE_CHANGE = '[Layout] RippleChange',
  SHOW_TOAST = '[Layout] ShowToast',
  SHOW_LOADING = '[Layout] ShowLoading',
  HIDE_LOADING = '[Layout] HideLoading',
  TOGGLE_DARK_MODE = '[Layout] Toggle DarkMode',
}

export const SidebarClick = createAction(LayoutActionTypes.SIDE_BAR);
export const ToggleMenu = createAction(LayoutActionTypes.MENU_TOGGLE);
export const LayoutClick = createAction(LayoutActionTypes.LAYOUT);
export const MegaMenuItemClick = createAction(LayoutActionTypes.MEGA_MENU_ITEM);
export const MegaMenuClick = createAction(LayoutActionTypes.MEGA_MENU);
export const RightPanelButtonClick = createAction(
  LayoutActionTypes.RIGHT_PANEL_ITEM
);
export const RightPanelClose = createAction(
  LayoutActionTypes.RIGHT_PANEL_CLOSE
);
export const RightPanelClick = createAction(LayoutActionTypes.RIGHT_PANEL);
export const TopbarMobileMenuButtonClick = createAction(
  LayoutActionTypes.TOP_BAR_MOBILE_ITEM
);
export const MegaMenuMobileButtonClick = createAction(
  LayoutActionTypes.MEGA_MENU_MOBILE_ITEM
);
export const MenuButtonClick = createAction(LayoutActionTypes.MENU);
export const RippleChange = createAction(LayoutActionTypes.RIPPLE_CHANGE);

export const TopbarItemClick = createAction(
  LayoutActionTypes.TOP_BAR_ITEM,
  props<{
    payload: { item: string };
  }>()
);

export const MenuItemButtonClick = createAction(
  LayoutActionTypes.MENU_ITEM,
  props<{
    payload: { key: string };
  }>()
);

export const ShowToast = createAction(
  LayoutActionTypes.SHOW_TOAST,
  props<{
    message: Message | Message[];
  }>()
);

export const ShowLoading = createAction(
  LayoutActionTypes.SHOW_LOADING,
  props<{
    id: string;
    context: string | null;
  }>()
);
export const HideLoading = createAction(
  LayoutActionTypes.HIDE_LOADING,
  props<{
    id: string;
  }>()
);

export const ToggleDarkMode = createAction(
  LayoutActionTypes.TOGGLE_DARK_MODE,
  props<{
    enabled: boolean;
  }>()
);

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Appointment, AppointmentStatus } from '@generated/models';
import { isBefore, parseISO } from 'date-fns';
import { ConfirmEventType, ConfirmationService, MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-appointment-state-selector',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <p-splitButton
      pBadge
      severity="danger"
      [badgeDisabled]="!showBadge || !!(badgeDisabled$ | async)"
      [pTooltip]="showLabel ? '' : labels[status]"
      tooltipPosition="top"
      [label]="showLabel ? labels[status] || '' : ''"
      appendTo="body"
      [model]="(items$ | async) || []"
      [icon]="icons[status]"
      [styleClass]="'p-button-sm ' + styleClasses[status]"
      menuStyleClass="w-14rem"
      (click)="onButtonClick($event)"
    ></p-splitButton>
    <p-confirmDialog
      appendTo="body"
      [style]="{ width: '450px' }"
    ></p-confirmDialog>
  `,
  styles: [],
  providers: [ConfirmationService],
})
export class AppointmentStateSelectorComponent {
  _appointment: Appointment | null = null;
  status: AppointmentStatus = AppointmentStatus.Pending;
  badgeDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @Input({ required: true }) set appointment(appointment: Appointment | null) {
    if (appointment) {
      this._appointment = appointment;
      this.status = appointment.status;
      this.items$.next(
        this.getItems({
          status: appointment.status,
          startDate: appointment.startDate,
        }),
      );
      this.badgeDisabled$.next(
        appointment.status !== AppointmentStatus.Completed ||
          !!appointment?.outcome,
      );
    }
  }
  @Input() showBadge: boolean = true;
  @Input() showLabel: boolean = true;
  @Output() changeStatus: EventEmitter<{
    status: AppointmentStatus;
    redirect: boolean;
  }> = new EventEmitter<{ status: AppointmentStatus; redirect: boolean }>();

  labels: Record<AppointmentStatus, string> = {
    [AppointmentStatus.Pending]: 'Da confermare',
    [AppointmentStatus.Confirmed]: 'Confermato',
    [AppointmentStatus.NoShow]: 'No show',
    [AppointmentStatus.CanceledByCustomer]: 'Cancellato dal cliente',
    [AppointmentStatus.Deleted]: 'Annullato',
    [AppointmentStatus.Completed]: 'Completato',
  };

  styleClasses: Record<AppointmentStatus, string> = {
    [AppointmentStatus.Pending]: 'p-button-warning',
    [AppointmentStatus.Confirmed]: 'p-button-primary',
    [AppointmentStatus.NoShow]: 'p-button-secondary',
    [AppointmentStatus.CanceledByCustomer]: 'p-button-danger',
    [AppointmentStatus.Deleted]: 'p-button-danger',
    [AppointmentStatus.Completed]: 'p-button-success',
  };

  icons: Record<AppointmentStatus, string> = {
    [AppointmentStatus.Pending]: 'pi pi-hourglass',
    [AppointmentStatus.Confirmed]: 'pi pi-thumbs-up',
    [AppointmentStatus.NoShow]: 'pi pi-eye-slash',
    [AppointmentStatus.CanceledByCustomer]: 'pi pi-times',
    [AppointmentStatus.Deleted]: 'pi pi-times',
    [AppointmentStatus.Completed]: 'pi pi-check',
  };

  items$: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);

  constructor(private readonly confirmationService: ConfirmationService) {}

  onUpdate(status: AppointmentStatus): void {
    if (status === AppointmentStatus.Completed) {
      this.confirmationService.confirm({
        message: `Vuoi compilare l'esito di questo appuntamento?`,
        header: 'Esito',
        icon: 'pi pi-info-circle',
        accept: () => this.changeStatus.emit({ status, redirect: true }),
        reject: (e: ConfirmEventType) => {
          if (e === ConfirmEventType.REJECT) {
            this.changeStatus.emit({ status, redirect: false });
          }
        },
      });
      return;
    }
    this.changeStatus.emit({ status, redirect: false });
  }

  getItems({
    status,
    startDate,
  }: {
    status: AppointmentStatus;
    startDate: string;
  }): MenuItem[] {
    const isStarted: boolean = isBefore(parseISO(startDate), new Date());
    const enabled: Record<AppointmentStatus, AppointmentStatus[]> = {
      [AppointmentStatus.CanceledByCustomer]: [],
      [AppointmentStatus.Deleted]: [],
      [AppointmentStatus.Completed]: [],
      [AppointmentStatus.NoShow]: [],
      [AppointmentStatus.Confirmed]: isStarted
        ? [AppointmentStatus.Completed, AppointmentStatus.NoShow]
        : [
            AppointmentStatus.CanceledByCustomer,
            AppointmentStatus.Deleted,
            AppointmentStatus.Pending,
          ],
      [AppointmentStatus.Pending]: isStarted
        ? [
            AppointmentStatus.Completed,
            AppointmentStatus.NoShow,
            AppointmentStatus.Confirmed,
          ]
        : [
            AppointmentStatus.CanceledByCustomer,
            AppointmentStatus.Deleted,
            AppointmentStatus.Confirmed,
          ],
    };

    return (Object.keys(this.labels) as AppointmentStatus[])
      .filter((key) => key !== status)
      .map((key) => ({
        command: () => this.onUpdate(key),
        icon: this.icons[key],
        label: this.labels[key],
        disabled: !enabled[status].includes(key),
      }));
  }

  onButtonClick(event: any): void {
    event.stopPropagation();
  }
}

import { Component, inject } from '@angular/core';
import { isLoading } from '@app/app/core/store/selectors/layout.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  template: `
    <div
      *ngIf="isLoading$ | async"
      class="fixed w-full h-full bg-black-alpha-20 top-0 left-0 flex align-items-center justify-content-center"
      [ngStyle]="{ zIndex: 999 }"
    >
      <p-progressSpinner></p-progressSpinner>
    </div>
  `,
  styles: [],
})
export class LoaderComponent {
  private readonly store = inject(Store);
  isLoading$: Observable<boolean> = this.store.select(isLoading);

  constructor() {}
}

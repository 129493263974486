import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutState } from '../../../../store/reducers/layout.reducers';

@Component({
  selector: 'app-topbar',
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
          <div class="layout-topbar-logo-wrapper">
            <a href="#" class="layout-topbar-logo">
              <img
                src="/assets/images/topbar/deal-icon-roma.png"
                alt="mirage-layout"
              />
              <span class="app-name">Romeo</span>
            </a>
          </div>
          <!-- Bottone primary menu mobile -->
          <a class="sidebar-menu-button" (click)="menuButtonClick.emit($event)">
            <i class="pi pi-bars"></i>
          </a>

          <!-- Bottone mega menu mobile -->
          <!-- <a
            class="megamenu-mobile-button"
            (click)="megaMenuMobileButtonClick.emit($event)"
          >
            <i class="pi pi-align-right megamenu-icon"></i>
          </a> -->

          <!-- Bottone topbar menu mobile -->
          <a
            class="topbar-menu-mobile-button"
            (click)="topbarMobileMenuButtonClick.emit($event)"
          >
            <i class="pi pi-ellipsis-v"></i>
          </a>

          <!-- Bottone mega menu -->
          <!-- <div class="layout-megamenu-wrapper">
            <a
              class="layout-megamenu-button"
              (click)="megaMenuButtonClick.emit($event)"
            >
              <i class="pi pi-comment pr-2"></i>Mega Menu
            </a>
            <app-megamenu
              [megaMenuActive]="layout?.megaMenuActive"
              (megaMenuButtonClick)="megaMenuButtonClick.emit($event)"
            ></app-megamenu>
          </div> -->
        </div>
        <div class="layout-topbar-right fadeInDown">
          <ul class="layout-topbar-actions">
            <!-- <li
              #search
              class="search-item topbar-item"
              [ngClass]="{
                'active-topmenuitem': layout?.activeTopbarItem === 'search'
              }"
            >
              <a
                (click)="
                  topbarItemClick.emit({ event: $event, item: 'search' })
                "
                class="topbar-search-mobile-button"
              >
                <i class="topbar-icon pi pi-search"></i>
              </a>
              <ul class="search-item-submenu fadeInDown">
                <li>
                  <span class="md-inputfield search-input-wrapper">
                    <input pInputText placeholder="Search..." />
                    <i class="pi pi-search"></i>
                  </span>
                </li>
              </ul>
            </li>
            <li
              #calendar
              class="topbar-item hover"
              [ngClass]="{
                'active-topmenuitem': layout?.activeTopbarItem === 'calendar'
              }"
              (clickOutside)="
                layout?.activeTopbarItem === 'calendar' &&
                  topbarItemClick.emit({ event: $event, item: 'calendar' })
              "
            >
              <a
                (click)="
                  topbarItemClick.emit({ event: $event, item: 'calendar' })
                "
              >
                <i class="topbar-icon pi pi-calendar"></i>
              </a>
              <ul app-topbar-calendar class="fadeInDown"></ul>
            </li>

            <li
              #message
              class="topbar-item hover"
              [ngClass]="{
                'active-topmenuitem': layout?.activeTopbarItem === 'message'
              }"
              (clickOutside)="
                layout?.activeTopbarItem === 'message' &&
                  topbarItemClick.emit({ event: $event, item: 'message' })
              "
            >
              <a
                (click)="
                  topbarItemClick.emit({ event: $event, item: 'message' })
                "
              >
                <i class="topbar-icon pi pi-inbox"></i>
              </a>
              <ul app-topbar-message class="fadeInDown"></ul>
            </li>

            <li
              #gift
              class="topbar-item hover"
              [ngClass]="{
                'active-topmenuitem': layout?.activeTopbarItem === 'gift'
              }"
              (clickOutside)="
                layout?.activeTopbarItem === 'gift' &&
                  topbarItemClick.emit({ event: $event, item: 'gift' })
              "
            >
              <a
                (click)="topbarItemClick.emit({ event: $event, item: 'gift' })"
              >
                <i class="topbar-icon pi pi-envelope"></i>
              </a>
              <ul app-topbar-gift class="fadeInDown"></ul>
            </li> -->

            <li
              #profile
              class="topbar-item profile-item hover"
              [ngClass]="{
                'active-topmenuitem': layout?.activeTopbarItem === 'profile'
              }"
              (clickOutside)="
                layout?.activeTopbarItem === 'profile' &&
                  topbarItemClick.emit({ event: $event, item: 'profile' })
              "
            >
              <a
                (click)="
                  topbarItemClick.emit({ event: $event, item: 'profile' })
                "
              >
                <span class="profile-image-wrapper">
                  <img
                    src="/assets/images/topbar/deal-icon-babylon.png"
                    alt="mirage-layout"
                  />
                </span>
                <span class="profile-info-wrapper">
                  <h3>Daniele Malavasi</h3>
                  <span>Admin</span>
                </span>
              </a>
              <ul
                app-topbar-profile
                class="profile-item-submenu fadeInDown"
              ></ul>
            </li>
            <!-- <li>
              <a
                class="layout-rightpanel-button hover"
                (click)="rightPanelButtonClick.emit($event)"
              >
                <i class="pi pi-arrow-left"></i>
              </a>
            </li> -->
          </ul>

          <ul class="profile-mobile-wrapper">
            <li
              #mobileProfile
              class="topbar-item profile-item"
              [ngClass]="{
                'active-topmenuitem':
                  layout?.activeTopbarItem === 'mobileProfile'
              }"
            >
              <a
                (click)="
                  topbarItemClick.emit({ event: $event, item: 'mobileProfile' })
                "
              >
                <span class="profile-image-wrapper">
                  <img
                    src="/assets/images/topbar/deal-icon-babylon.png"
                    alt="mirage-layout"
                  />
                </span>
                <span class="profile-info-wrapper">
                  <h3>Daniele Malavasi</h3>
                  <span>Admin</span>
                </span>
              </a>
              <ul app-topbar-profile-mobile class="fadeInDown"></ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
})
export class TopbarComponent {
  @Input() layout!: LayoutState | null;

  @Output() menuButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() megaMenuMobileButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() megaMenuButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() topbarMobileMenuButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() topbarItemClick: EventEmitter<{ event: MouseEvent; item: string }> =
    new EventEmitter<{ event: MouseEvent; item: string }>();
  @Output() rightPanelButtonClick: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();

  activeItem!: number | null;

  mobileMegaMenuItemClick(index: number) {
    this.activeItem = this.activeItem === index ? null : index;
  }
}

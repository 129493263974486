import { Component } from '@angular/core';

@Component({
  selector: '[app-topbar-profile-mobile]',
  template: `
    <li class="profile-submenu-header">
      <div class="performance">
        <span>Weekly Performance</span>
        <img src="/assets/images/topbar/asset-bars.svg" alt="mirage-layout" />
      </div>
      <div class="profile">
        <img
          src="/assets/images/topbar/deal-icon-babylon.png"
          alt="mirage-layout"
          width="45"
        />
        <h1>Daniele Malavasi</h1>
        <span>Admin</span>
      </div>
    </li>
    <li>
      <i class="pi pi-list icon icon-1"></i>
      <div class="menu-text">
        <p>Tasks</p>
        <span>3 open issues</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li>
      <i class="pi pi-shopping-cart icon icon-2"></i>
      <div class="menu-text">
        <p>Payments</p>
        <span>24 new</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li>
      <i class="pi pi-users icon icon-3"></i>
      <div class="menu-text">
        <p>Clients</p>
        <span>+80%</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-footer">
      <button [routerLink]="['/', 'account', 'logout']" class="signout-button">
        Sign Out
      </button>
    </li>
  `,
  styles: [],
})
export class TopbarProfileMobileComponent {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-customer-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="size === 'small'">
      <p-avatar shape="circle">
        <div class="w-full h-full" *ngIf="image; else placeholder">
          <p-image
            [imageStyle]="{ 'object-fit': 'cover' }"
            styleClass="w-full h-full"
            [src]="image | storagePath"
          />
        </div>
        <ng-template #placeholder>
          <i class="pi pi-user"></i>
        </ng-template>
      </p-avatar>
    </ng-container>

    <ng-container *ngIf="size === 'large'">
      <div class="relative" *ngIf="image; else placeholder">
        <p-avatar size="xlarge" shape="circle">
          <div class="w-full h-full">
            <p-image
              [src]="image | storagePath"
              [imageStyle]="{ 'object-fit': 'cover' }"
              styleClass="w-full h-full"
            />
          </div>
        </p-avatar>
        <div
          class="absolute z-10 p-avatar border-circle"
          [ngStyle]="{ right: '-.5rem', bottom: '-.5rem' }"
        >
          <i class="pi pi-user"></i>
        </div>
      </div>
      <ng-template #placeholder>
        <p-avatar size="xlarge" shape="circle">
          <i class="pi pi-user" style="font-size:2rem"></i>
        </p-avatar>
      </ng-template>
    </ng-container>
  `,
  styles: [],
})
export class CustomerAvatarComponent {
  @Input() image: string | null | undefined;
  @Input() size: 'small' | 'large' = 'small';
}

import { Injectable } from '@angular/core';
import { Company } from '@generated/models';
import { CompanyService } from '@generated/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import {
  ReadCompany,
  ReadCompanyFailed,
  ReadCompanySuccess,
} from '../actions/company.action';
import { ShowToast } from '../actions/layout.action';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private readonly companyService: CompanyService,
  ) {}

  readCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReadCompany),
      switchMap((action) =>
        this.companyService.readCompany().pipe(
          map((company: Company) => ReadCompanySuccess({ company })),
          catchError(() => [
            ShowToast({
              message: {
                severity: 'error',
                detail: `Errore`,
                summary: `Compagnia non trovata`,
              },
            }),
            ReadCompanyFailed(),
          ]),
        ),
      ),
    ),
  );
}

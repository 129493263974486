import { Company } from '@generated/models';
import { createAction, props } from '@ngrx/store';

const CompanyActionTypes = {
  READ_COMPANY: '[CORE > Company] read company',
  READ_COMPANY_SUCCESS: '[CORE > Company] read company success',
  READ_COMPANY_FAILED: '[CORE > Company] read company failed',
} as const;
type CompanyActionTypes =
  (typeof CompanyActionTypes)[keyof typeof CompanyActionTypes];

export const ReadCompany = createAction(CompanyActionTypes.READ_COMPANY);

export const ReadCompanySuccess = createAction(
  CompanyActionTypes.READ_COMPANY_SUCCESS,
  props<{
    company: Company;
  }>(),
);

export const ReadCompanyFailed = createAction(
  CompanyActionTypes.READ_COMPANY_FAILED,
);

import { NgModule } from '@angular/core';
import {
  UppyAngularDashboardModalModule,
  UppyAngularDashboardModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularStatusBarModule,
} from '@uppy/angular';

const uppyNgModules = [
  UppyAngularDashboardModalModule,
  UppyAngularDashboardModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularStatusBarModule,
];

@NgModule({
  declarations: [],
  imports: [...uppyNgModules],
  exports: [...uppyNgModules],
})
export class UppyModule {}

import { Injectable } from '@angular/core';
import { IamSession } from '@fullstackagency/iam';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, iif, switchMap } from 'rxjs';
import { ReadCompany } from '../actions/company.action';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
} from '../actions/user.action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private readonly iamSession: IamSession,
  ) {}

  checkSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckSession),
      switchMap(() =>
        from(this.iamSession.exists()).pipe(
          switchMap((exists) =>
            iif(
              () => exists,
              [CheckSessionSuccess(), ReadCompany()],
              [CheckSessionFailed()],
            ),
          ),
        ),
      ),
    ),
  );
}

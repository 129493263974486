import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutState } from '../reducers/layout.reducers';

export const selectLayout = createFeatureSelector<LayoutState>('layout');
export const toastMessages = createSelector(
  selectLayout,
  (state) => state.toastMessages
);
export const isLoading = createSelector(
  selectLayout,
  (state) => !!Object.keys(state.loading).length
);
export const darkMode = createSelector(
  selectLayout,
  (state): boolean => state.darkMode
);

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { hasActivityGuard } from './core/guards/has-activities.guard';
import { hasSessionGuard } from './core/guards/has-session.guard';
import { LayoutAppComponent } from './core/layout/app/components/layout-app.component';
import { firstAccessGuard } from './features/first-access/guards/first-access.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/agenda',
    pathMatch: 'full',
  },
  {
    path: 't',
    loadChildren: () =>
      import('./features/short-url/short-url.module').then(
        (m) => m.ShortUrlModule,
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./features/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: '',
    canActivate: [hasSessionGuard],
    children: [
      {
        path: '',
        component: LayoutAppComponent,
        canActivate: [hasActivityGuard],
        children: [
          {
            path: 'settings',
            loadChildren: () =>
              import('./features/settings/settings.module').then(
                (m) => m.SettingsModule,
              ),
          },
          {
            path: 'agenda',
            loadChildren: () =>
              import('./features/agenda/agenda.module').then(
                (m) => m.AgendaModule,
              ),
          },
          {
            path: 'appointments',
            loadChildren: () =>
              import('./features/appointments/appointments.module').then(
                (m) => m.AppointmentsModule,
              ),
          },
          {
            path: 'customers',
            loadChildren: () =>
              import('./features/customers/customers.module').then(
                (m) => m.CustomersModule,
              ),
          },
          {
            path: 'operators',
            loadChildren: () =>
              import('./features/operators/operators.module').then(
                (m) => m.OperatorsModule,
              ),
          },
        ],
      },
      {
        path: 'welcome',
        canActivate: [firstAccessGuard],
        loadChildren: () =>
          import('./features/first-access/first-access.module').then(
            (m) => m.FirstAccessModule,
          ),
      },
    ],
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./features/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

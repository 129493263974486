import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, shareReplay } from 'rxjs';
import packageJson from '../../../../../../../../package.json';
import {
  MegaMenuClick,
  MegaMenuMobileButtonClick,
  MenuButtonClick,
  MenuItemButtonClick,
  RightPanelButtonClick,
  RightPanelClick,
  RightPanelClose,
  ToggleMenu,
  TopbarItemClick,
  TopbarMobileMenuButtonClick,
} from '../../../store/actions/layout.action';
import { LayoutState } from '../../../store/reducers/layout.reducers';
import { selectLayout } from '../../../store/selectors/layout.selectors';

@Component({
  selector: 'app-layout',
  template: `
    <div
      class="layout-wrapper layout-menu-romeo"
      [ngClass]="{
        'layout-horizontal': (layout$ | async)?.horizontalMenu,
        'layout-wrapper-static': (layout$ | async)?.staticMenuActive,
        'layout-rightpanel-active': (layout$ | async)?.rightPanelActive,
        'layout-topbar-mobile-active': (layout$ | async)
          ?.topbarMobileMenuActive,
        'layout-megamenu-mobile-active': (layout$ | async)
          ?.megaMenuMobileActive,
        'layout-sidebar-mobile-active': (layout$ | async)?.menuMobileActive,
        'p-input-filled': (layout$ | async)?.inputStyle === 'filled',
        'p-ripple-disabled': !(layout$ | async)?.ripple
      }"
    >
      <app-primary-menu
        [layout]="layout$ | async"
        (toggleMenu)="toggleMenu()"
        (selectPrimaryMenuItem)="selectPrimaryMenuItem($event)"
      >
      </app-primary-menu>

      <div class="layout-main">
        <app-topbar
          [layout]="layout$ | async"
          (menuButtonClick)="menuButtonClick($event)"
          (megaMenuMobileButtonClick)="megaMenuMobileButtonClick($event)"
          (megaMenuButtonClick)="megaMenuButtonClick($event)"
          (topbarMobileMenuButtonClick)="topbarMobileMenuButtonClick($event)"
          (topbarItemClick)="topbarItemClick($event)"
          (rightPanelButtonClick)="rightPanelButtonClick($event)"
        >
        </app-topbar>

        <app-right-panel
          (rightPanelClick)="rightPanelClick($event)"
          (rightPanelClose)="rightPanelClose($event)"
        >
        </app-right-panel>

        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content relative">
          <router-outlet></router-outlet>
        </div>

        <app-footer [version]="version"></app-footer>

        <!--<app-config></app-config>-->

        <div class="layout-main-mask"></div>
      </div>
    </div>
    <app-toast />
  `,
})
export class LayoutAppComponent {
  layout$: Observable<LayoutState> = this.store
    .select(selectLayout)
    .pipe(shareReplay());
  version: string = packageJson.version;

  constructor(private store: Store) {}

  toggleMenu() {
    this.store.dispatch(ToggleMenu());
  }

  menuButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(MenuButtonClick());
  }

  megaMenuMobileButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(MegaMenuMobileButtonClick());
  }

  megaMenuButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(MegaMenuClick());
  }

  topbarMobileMenuButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(TopbarMobileMenuButtonClick());
  }

  topbarItemClick(event: { event: MouseEvent; item: string }) {
    event.event.stopPropagation();
    this.store.dispatch(TopbarItemClick({ payload: { item: event.item } }));
  }

  rightPanelButtonClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(RightPanelButtonClick());
  }

  selectPrimaryMenuItem(key: string) {
    this.store.dispatch(MenuItemButtonClick({ payload: { key } }));
  }

  rightPanelClick(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(RightPanelClick());
  }

  rightPanelClose(event: MouseEvent) {
    event.stopPropagation();
    this.store.dispatch(RightPanelClose());
  }
}

import { Component, inject } from '@angular/core';
import { ToggleDarkMode } from '@app/app/core/store/actions/layout.action';
import { darkMode } from '@app/app/core/store/selectors/layout.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: '[app-topbar-profile]',
  template: `
    <li class="profile-submenu-header">
      <!-- <div class="performance">
        <span>Weekly Performance</span>
        <img src="/assets/images/topbar/asset-bars.svg" alt="mirage-layout" />
      </div> -->
      <div class="profile w-full">
        <img
          src="/assets/images/topbar/deal-icon-babylon.png"
          alt="mirage-layout"
          width="40"
        />
        <h1>Daniele Malavasi</h1>
        <span>Admin</span>
      </div>
    </li>
    <li class="layout-submenu-item">
      <i class="pi pi-sun icon"></i>
      <div class="menu-text flex-1">
        <p>Dark mode</p>
      </div>
      <p-inputSwitch
        [ngModel]="darkMode$ | async"
        (ngModelChange)="toggleDarkMode($event)"
      ></p-inputSwitch>
    </li>
    <!-- <li class="layout-submenu-item">
      <i class="pi pi-list icon icon-1"></i>
      <div class="menu-text">
        <p>Tasks</p>
        <span>3 open issues</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-item">
      <i class="pi pi-shopping-cart icon icon-2"></i>
      <div class="menu-text">
        <p>Payments</p>
        <span>24 new</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-item">
      <i class="pi pi-users icon icon-3"></i>
      <div class="menu-text">
        <p>Clients</p>
        <span>+80%</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li> -->
    <li class="layout-submenu-footer">
      <button class="signout-button" [routerLink]="['/', 'account', 'logout']">
        Sign Out
      </button>
    </li>
  `,
  styles: [],
})
export class TopbarProfileComponent {
  private readonly store = inject(Store);

  constructor() {}
  darkMode$: Observable<boolean> = this.store.select(darkMode);

  toggleDarkMode(enabled: boolean): void {
    this.store.dispatch(ToggleDarkMode({ enabled }));
  }
}

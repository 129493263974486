import {
  Component,
  DoCheck,
  Injector,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  Validators,
} from '@angular/forms';
import { formatDuration } from '../../helpers/format-duration.helper';

@Component({
  selector: 'app-duration-input',
  template: `
    <input pInputText [formControl]="formControl" [readonly]="true" />
  `,
  styles: [],
})
export class DurationInputComponent
  implements OnInit, DoCheck, ControlValueAccessor
{
  onChange: any = () => {};
  onTouch: any = () => {};

  _value: number = 0;
  set value(value: number) {
    this._value = value;
    this.formControl.setValue(formatDuration(value, false));
    // this.onChange(value);
    // this.onTouch();
  }

  formControl = new FormControl<string>('');

  disabled: boolean = false;

  constructor(
    @Self() @Optional() private ngControl: NgControl,
    private readonly injector: Injector,
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  ngOnInit(): void {
    let control: FormControl | null = null;
    if (this.ngControl) {
      if (this.ngControl instanceof FormControlName) {
        control = this.injector
          .get(FormGroupDirective)
          .getControl(this.ngControl);
      } else {
        control = (this.ngControl as FormControlDirective).form;
      }
    }
    if (control?.hasValidator(Validators.required)) {
      this.formControl.addValidators(Validators.required);
    }
  }

  ngDoCheck(): void {
    if (this.ngControl?.dirty) {
      this.formControl.markAsDirty();
    } else {
      this.formControl.markAsPristine();
    }
  }

  writeValue(value: number): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

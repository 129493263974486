import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UppyFile } from '@uppy/core';
import { BehaviorSubject, Observable, Subject, combineLatest, map } from 'rxjs';
import { storagePath } from '../../helpers/storage-path.helper';
import { UploadFileMeta } from '../upload-file/upload-file-meta.interface';
import { AvatarImage } from './avatar-image.interface';

@Component({
  selector: 'app-avatar-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="relative">
      <ng-container *ngIf="image$ | async as imageVal">
        <div
          class="absolute z-1"
          [ngStyle]="{ top: '-.5rem', right: '-.25rem' }"
        >
          <p-badge
            *ngIf="tmpImage$ | async"
            value="1"
            severity="danger"
            [style]="{
              'font-size': '.7rem',
              'min-width': '1.25rem',
              width: '1.25rem',
              height: '1.25rem',
              'line-height': '1.25rem'
            }"
          ></p-badge>
        </div>
        <p-avatar size="xlarge" shape="circle">
          <div *ngIf="imageVal.id; else placeholder" class="w-full h-full">
            <p-image
              [src]="imageVal.url"
              [preview]="true"
              [imageStyle]="{ 'object-fit': 'cover' }"
              styleClass="w-full h-full"
              (click)="$event.preventDefault()"
            />
          </div>
          <ng-template #placeholder>
            <i
              *ngIf="iconType === 'icon'; else fontawesomeIcon"
              [ngClass]="icon"
              style="font-size:2rem"
            ></i>
            <ng-template #fontawesomeIcon>
              <fa-icon [icon]="$any(icon)" />
            </ng-template>
          </ng-template>
        </p-avatar>
      </ng-container>

      <div class="absolute" [ngStyle]="{ right: '-.5rem', bottom: '-.5rem' }">
        <div [ngClass]="{ hidden: (currentImage$ | async) }">
          <app-upload-file
            (fileChanged)="onUploadFileChanged($event)"
            (uploadStarted)="uploadStarted.emit($event)"
            (uploadCompleted)="uploadCompleted.emit($event)"
            [uploadFiles]="uploadFiles"
            [removeFile]="removeFile$"
            [restrictions]="{
              allowedFileTypes: ['image/*'],
              maxNumberOfFiles: 1
            }"
          >
            <ng-template appUploadFileButton>
              <p-button
                icon="pi pi-image"
                styleClass="p-button-rounded p-button-sm w-2rem h-2rem p-button-help"
              ></p-button>
            </ng-template>
          </app-upload-file>
        </div>
        <p-button
          *ngIf="currentImage$ | async as currentImage"
          icon="pi pi-trash"
          styleClass="p-button-rounded p-button-sm w-2rem h-2rem p-button-danger"
          (onClick)="onDelete(currentImage)"
        ></p-button>
      </div>
    </div>
  `,
  styles: [],
})
export class AvatarInputComponent {
  removeFile$: Subject<string> = new Subject();
  readonly tmpImage$: BehaviorSubject<AvatarImage | null> =
    new BehaviorSubject<AvatarImage | null>(null);
  readonly currentImage$: BehaviorSubject<AvatarImage | null> =
    new BehaviorSubject<AvatarImage | null>(null);

  readonly defaultImageValue: AvatarImage = {
    id: '',
    url: '',
    uploaded: false,
  };
  image$: Observable<AvatarImage> = combineLatest({
    tmpImage: this.tmpImage$,
    currentImage: this.currentImage$,
  }).pipe(
    map(
      ({ tmpImage, currentImage }) =>
        tmpImage || currentImage || this.defaultImageValue,
    ),
  );

  @Input({ required: true }) uploadFiles: Subject<UploadFileMeta> | undefined;
  @Input() set image(image: string | null) {
    this.currentImage$.next(
      image ? { id: image, url: storagePath(image), uploaded: true } : null,
    );
  }

  icon: string | IconProp = 'pi pi-user';
  iconType: 'fontawesome' | 'icon' = 'icon';
  @Input() set placeholder(placeholder: string | IconProp) {
    this.icon = placeholder;
    this.iconType = typeof placeholder === 'string' ? 'icon' : 'fontawesome';
  }

  @Output() uploadStarted: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadCompleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  @Output() dirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  onUploadFileChanged(files: UppyFile[]) {
    const tmpFiles: AvatarImage[] = files
      .map((file: UppyFile) =>
        file.preview
          ? { id: file.id, uploaded: false, url: file.preview }
          : null,
      )
      .filter((v): v is { id: string; uploaded: boolean; url: string } => !!v);
    this.tmpImage$.next(tmpFiles[0] || null);
    this.dirty.emit(!!tmpFiles[0]);
  }

  onDelete(image: AvatarImage): void {
    if (!image.uploaded) {
      this.removeFile$.next(image.id);
    } else {
      this.delete.emit(image.id);
    }
  }
}

import { createReducer, on } from '@ngrx/store';
import { Message } from 'primeng/api';
import * as LayoutActions from '../actions/layout.action';

export interface LayoutState {
  rightPanelClick: boolean;
  rightPanelActive: boolean;
  menuClick: boolean;
  staticMenuActive: boolean;
  menuMobileActive: boolean;
  megaMenuClick: boolean;
  megaMenuActive: boolean;
  megaMenuMobileClick: boolean;
  megaMenuMobileActive: boolean;
  topbarItemClick: boolean;
  topbarMobileMenuClick: boolean;
  topbarMobileMenuActive: boolean;
  sidebarActive: boolean;
  activePrimaryMenuItem: string;
  activeTopbarItem: string;
  topbarMenuActive: boolean;
  menuHoverActive: boolean;
  configActive: boolean;
  horizontalMenu: boolean;
  darkMode: boolean;
  ripple: boolean;
  inputStyle: string;
  toastMessages: Message[];
  loading: Record<string, string | null>;
}

export const initialLayoutState: LayoutState = {
  rightPanelClick: false,
  rightPanelActive: false,
  menuClick: false,
  staticMenuActive: false,
  menuMobileActive: false,
  megaMenuClick: false,
  megaMenuActive: false,
  megaMenuMobileClick: false,
  megaMenuMobileActive: false,
  topbarItemClick: false,
  topbarMobileMenuClick: false,
  topbarMobileMenuActive: false,
  sidebarActive: false,
  activeTopbarItem: '',
  activePrimaryMenuItem: '',
  topbarMenuActive: false,
  menuHoverActive: false,
  configActive: false,
  horizontalMenu: false,
  darkMode: false,
  ripple: false,
  inputStyle: 'outlined',
  toastMessages: [],
  loading: {},
};

export const layoutReducer = createReducer(
  initialLayoutState,
  on(
    LayoutActions.SidebarClick,
    (state): LayoutState => ({
      ...state,
    })
  ),
  on(
    LayoutActions.ToggleMenu,
    (state): LayoutState => ({
      ...state,
      staticMenuActive: !state.staticMenuActive,
    })
  ),
  on(
    LayoutActions.LayoutClick,
    (state): LayoutState => ({
      ...state,
      megaMenuActive: false,
      menuMobileActive: false,
      megaMenuMobileActive: false,
      topbarMobileMenuActive: false,
      activeTopbarItem: '',
    })
  ),
  on(
    LayoutActions.MegaMenuItemClick,
    (state): LayoutState => ({
      ...state,
    })
  ),
  on(
    LayoutActions.MegaMenuClick,
    (state): LayoutState => ({
      ...state,
      megaMenuActive: !state.megaMenuActive,
      menuMobileActive: false,
      megaMenuMobileActive: false,
      topbarMobileMenuActive: false,
      activeTopbarItem: '',
    })
  ),
  on(
    LayoutActions.TopbarItemClick,
    (state, action): LayoutState => ({
      ...state,
      activeTopbarItem:
        action.payload.item === state.activeTopbarItem
          ? ''
          : action.payload.item,
      megaMenuActive: false,
      menuMobileActive: false,
      megaMenuMobileActive: false,
      // topbarMobileMenuActive: false,
    })
  ),
  on(
    LayoutActions.RightPanelButtonClick,
    (state): LayoutState => ({
      ...state,
      rightPanelActive: !state.rightPanelActive,
    })
  ),
  on(
    LayoutActions.RightPanelClose,
    (state): LayoutState => ({
      ...state,
      rightPanelActive: false,
    })
  ),
  on(
    LayoutActions.RightPanelClick,
    (state): LayoutState => ({
      ...state,
    })
  ),
  on(
    LayoutActions.TopbarMobileMenuButtonClick,
    (state): LayoutState => ({
      ...state,
      topbarMobileMenuActive: !state.topbarMobileMenuActive,
      menuMobileActive: false,
      megaMenuMobileActive: false,
      activeTopbarItem: '',
    })
  ),
  on(
    LayoutActions.MegaMenuMobileButtonClick,
    (state): LayoutState => ({
      ...state,
      megaMenuMobileActive: !state.megaMenuMobileActive,
      menuMobileActive: false,
      topbarMobileMenuActive: false,
      activeTopbarItem: '',
    })
  ),
  on(
    LayoutActions.MenuButtonClick,
    (state): LayoutState => ({
      ...state,
      menuMobileActive: !state.menuMobileActive,
      megaMenuMobileActive: false,
      topbarMobileMenuActive: false,
      activeTopbarItem: '',
    })
  ),
  on(
    LayoutActions.MenuItemButtonClick,
    (state, action): LayoutState => ({
      ...state,
      activePrimaryMenuItem: action.payload.key,
    })
  ),
  on(
    LayoutActions.RippleChange,
    (state): LayoutState => ({
      ...state,
    })
  ),
  on(LayoutActions.ShowToast, (state, action) => ({
    ...state,
    toastMessages: Array.isArray(action.message)
      ? action.message
      : [action.message],
  })),
  on(LayoutActions.ShowLoading, (state, action) => ({
    ...state,
    loading: { ...state.loading, [action.id]: action.context },
  })),
  on(LayoutActions.HideLoading, (state, action) => {
    const loading = { ...state.loading };
    delete loading[action.id];
    return { ...state, loading };
  }),
  on(LayoutActions.ToggleDarkMode, (state, action) => ({
    ...state,
    darkMode: action.enabled,
  }))
);

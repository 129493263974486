import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { Score, ZxcvbnResult, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { minLengthMatcher } from './min-length.matcher';

@Component({
  selector: 'app-password-strength-meter',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div class="m-0 py-1 p-0 flex gap-1">
        <div
          *ngFor="let bar of bars"
          class="h-1 rounded-md w-10 bg-ft-neutral"
          [ngStyle]="{ backgroundColor: bar ?? undefined }"
        ></div>
      </div>
    </div>
  `,
})
export class PasswordStrengthMeterComponent {
  private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input({ required: true }) set passwordToCheck(passwordToCheck: string) {
    this.#change(passwordToCheck);
    this.cd.detectChanges();
  }
  @Output() passwordStrength = new EventEmitter<boolean>();

  bars: Array<string | null> = new Array(5).fill(null);
  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green'];
  #change(password: string): void {
    let pwdStrength: Score | null = !!password
      ? this.#checkStrength(password)
      : null;
    this.#setBarColors(pwdStrength);
    pwdStrength === 4
      ? this.passwordStrength.emit(true)
      : this.passwordStrength.emit(false);
  }

  #setBarColors(strength: Score | null) {
    this.bars = this.bars.map(() => null);
    if (strength === null) {
      return;
    }
    const colors = {
      index: strength + 1,
      color: this.colors[strength],
    };
    for (let n = 0; n < colors.index; n++) {
      this.bars[n] = colors.color;
    }
  }

  #checkStrength(password: string): ZxcvbnResult['score'] {
    !zxcvbnOptions.matchers['minLength'] &&
      zxcvbnOptions.addMatcher('minLength', minLengthMatcher(8));
    const result = zxcvbn(password);
    return result.score;
  }
}

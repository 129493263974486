import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutState } from '../../../../store/reducers/layout.reducers';

@Component({
  selector: 'app-primary-menu',
  template: `
    <div
      class="layout-sidebar"
      [ngClass]="{ 'layout-sidebar-active': sidebarActive }"
      (mouseover)="sidebarActive = true"
      (mouseleave)="sidebarActive = false"
    >
      <div class="sidebar-logo">
        <a
          class="sidebar-pin hover"
          title="Toggle Menu"
          (click)="toggleMenu.emit()"
        >
          <i class="pi pi-lock" *ngIf="layout?.staticMenuActive"></i>
          <i class="pi pi-lock-open" *ngIf="!layout?.staticMenuActive"></i>
        </a>
        <a routerLink="/">
          <img alt="logo" src="/assets/images/topbar/deal-icon-roma.png" />
          <span class="app-name">Romeo</span>
        </a>
      </div>

      <div class="layout-menu-container" (click)="onMenuClick()">
        <ul class="layout-menu">
          <li
            app-menuitem
            *ngFor="let item of model; let i = index"
            [item]="item"
            [index]="i"
            [root]="true"
            [activePrimaryMenuItem]="layout?.activePrimaryMenuItem"
            (selectPrimaryMenuItem)="selectPrimaryMenuItem.emit($event)"
          ></li>
        </ul>
      </div>
    </div>
  `,
})
export class PrimaryMenuComponent implements OnInit {
  @Input() layout!: LayoutState | null;
  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectPrimaryMenuItem: EventEmitter<string> =
    new EventEmitter<string>();

  model!: MenuItem[];
  sidebarActive = false;

  ngOnInit() {
    this.model = [
      {
        label: 'Agenda',
        icon: 'pi pi-fw pi-calendar',
        routerLink: ['/agenda'],
      },
      {
        label: 'Appuntamenti',
        icon: 'pi pi-fw pi-calendar-plus',
        routerLink: ['/appointments'],
      },
      {
        label: 'Clienti',
        icon: 'pi pi-fw pi-users',
        routerLink: ['/customers'],
      },
      {
        label: 'Collaboratori',
        icon: 'pi pi-fw pi-at',
        routerLink: ['/operators'],
      },
      {
        label: 'Impostazioni',
        icon: 'pi pi-cog pi-file',
        routerLink: ['/settings'],
      },
    ];
  }

  onMenuClick() {
    console.log('MenuClick');
  }
}

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-menuitem]',
  template: `
    <ng-container>
      <a
        [attr.href]="item.url"
        (click)="itemClick($event)"
        *ngIf="(!item.routerLink || item.items) && item.visible !== false"
        (keydown.enter)="itemClick($event)"
        [attr.target]="item.target"
        [attr.tabindex]="0"
      >
        <span class="menuitem-text">{{ item.label }}</span>
        <i
          class="pi pi-fw pi-angle-down layout-submenu-toggler"
          *ngIf="item.items"
        ></i>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
      </a>
      <a
        (click)="itemClick($event)"
        *ngIf="item.routerLink && !item.items && item.visible !== false"
        [routerLink]="item.routerLink"
        routerLinkActive="active-menuitem-routerlink"
        [attr.target]="item.target"
        [attr.tabindex]="0"
      >
        <span class="menuitem-text">{{ item.label }}</span>
        <i
          class="pi pi-fw pi-angle-down layout-submenu-toggler"
          *ngIf="item.items"
        ></i>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
      </a>
      <ul *ngIf="item.items && active && item.visible !== false">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
          <li
            app-menuitem
            [item]="child"
            [index]="i"
            [parentKey]="key"
            [activePrimaryMenuItem]="activePrimaryMenuItem"
          ></li>
        </ng-template>
      </ul>
    </ng-container>
  `,
  animations: [
    trigger('children', [
      state(
        'void',
        style({
          height: '0px',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class PrimaryMenuItemComponent implements OnInit {
  @HostBinding('class.active-menuitem')
  active = false;

  @Input() item!: MenuItem;
  @Input() index!: number;
  @Input() root!: boolean;
  @Input() parentKey!: string;
  @Input() set activePrimaryMenuItem(key: string | undefined) {
    if (this.active && this.key !== key && key?.indexOf(this.key) !== 0) {
      this.active = false;
    }
  }

  @Output() selectPrimaryMenuItem: EventEmitter<string> =
    new EventEmitter<string>();

  menuSourceSubscription!: Subscription;

  menuResetSubscription!: Subscription;

  key!: string;

  ngOnInit() {
    this.key = this.parentKey
      ? this.parentKey + '-' + this.index
      : String(this.index);
  }

  itemClick(event: Event) {
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    this.selectPrimaryMenuItem.emit(this.key);

    // TODO disattivato il meccanismo di "active" perchè era collegato al click sul menu e non al router attivo
    // if (this.item.items) {
    //   this.active = !this.active;
    // } else {
    //   this.active = true;
    // }
  }
}

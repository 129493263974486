import { createAction } from '@ngrx/store';

const UserActionTypes = {
  CHECK_SESSION: '[CORE > User] check session',
  CHECK_SESSION_SUCCESS: '[CORE > User] check session success',
  CHECK_SESSION_FAILED: '[CORE > User] check session failed',
} as const;
type UserActionTypes = (typeof UserActionTypes)[keyof typeof UserActionTypes];

export const CheckSession = createAction(UserActionTypes.CHECK_SESSION);

export const CheckSessionSuccess = createAction(
  UserActionTypes.CHECK_SESSION_SUCCESS,
);

export const CheckSessionFailed = createAction(
  UserActionTypes.CHECK_SESSION_FAILED,
);

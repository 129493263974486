import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { iif, map, of, race, switchMap, take } from 'rxjs';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
} from '../store/actions/user.action';
import { selectSessionExists } from '../store/selectors/user.selector';

export const hasSessionGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const action$ = inject(Actions);

  const checkSessionFailed$ = action$.pipe(
    ofType(CheckSessionFailed),
    take(1),
    map(() => router.createUrlTree(['/', 'account', 'login'])),
  );

  const checkSessionSuccess$ = action$.pipe(
    ofType(CheckSessionSuccess),
    take(1),
    map(() => true),
  );
  return store.select(selectSessionExists).pipe(
    take(1),
    switchMap((sessionExists: boolean | null) =>
      iif(
        () => {
          if (sessionExists !== true) {
            store.dispatch(CheckSession());
          }
          return sessionExists === true;
        },
        of(true),
        race(checkSessionFailed$, checkSessionSuccess$),
      ),
    ),
  );
};

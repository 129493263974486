import * as fromRouterStore from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { CompanyState } from '../interfaces/company.interface';
import { UserState } from '../interfaces/user-state.interface';
import { companyReducer } from './company.reducer';
import * as fromLayout from './layout.reducers';
import * as fromRouter from './router.reducer';
import { userReducer } from './user.reducer';

export interface State {
  router: fromRouterStore.RouterReducerState<fromRouter.RouterState>;
  layout: fromLayout.LayoutState;
  user: UserState;
  company: CompanyState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouterStore.routerReducer,
  layout: fromLayout.layoutReducer,
  user: userReducer,
  company: companyReducer,
};

import { Component } from '@angular/core';

@Component({
  selector: '[app-topbar-calendar]',
  template: `
    <li class="layout-submenu-header">
      <h1>Calendar</h1>
    </li>
    <li class="calendar">
      <p-calendar [inline]="true"></p-calendar>
    </li>
  `,
  styles: [],
})
export class TopbarCalendarComponent {}

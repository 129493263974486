import { CommonModule } from '@angular/common';
import { isDevMode, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CompanyEffects } from './effects/company.effects';
import { LayoutEffects } from './effects/layout.effects';
import { RouterEffects } from './effects/router.effects';
import { UserEffects } from './effects/user.effects';
import { reducers } from './reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      RouterEffects,
      LayoutEffects,
      UserEffects,
      CompanyEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'romeo',
      maxAge: 25,
      logOnly: !isDevMode(),
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
    }),
  ],
})
export class RomeoStoreModule {}

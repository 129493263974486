import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToggleDarkMode } from '../actions/layout.action';

@Injectable()
export class LayoutEffects {
  toggleDarkMode$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ToggleDarkMode),
        tap((action) => {
          const body = this.document.querySelector('body');
          const value = action.enabled ? 'dark' : 'light';
          body?.setAttribute('data-theme', value);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}
}

export const formatDuration = (value: number, showEmpty: boolean): string => {
  const minutes = value % 60;
  const hours = Math.floor(value / 60);
  let display = [];
  if (hours > 0) {
    display.push(`${hours} ${hours > 1 ? 'ore' : 'ora'}`);
  }
  if (minutes > 0) {
    display.push(`${minutes} min`);
  }
  if (showEmpty && display.length === 0) {
    return '0 min';
  }
  return display.join(', ');
};

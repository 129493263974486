import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from '../interfaces/company.interface';

export const selectCompanyState =
  createFeatureSelector<CompanyState>('company');
export const selectCompanyLoading = createSelector(
  selectCompanyState,
  (state) => state.loading,
);

export const selectCompany = createSelector(
  selectCompanyState,
  (state) => state.company,
);

import { createReducer, on } from '@ngrx/store';
import {
  CheckSession,
  CheckSessionFailed,
  CheckSessionSuccess,
} from '../actions/user.action';
import { UserState } from '../interfaces/user-state.interface';

const initialUserState: UserState = {
  loading: false,
  sessionExists: null,
};

export const userStateKey = 'user';

export const userReducer = createReducer(
  initialUserState,
  on(CheckSession, (state) => ({
    ...state,
    sessionExists: null,
  })),
  on(CheckSessionSuccess, (state) => ({
    ...state,
    sessionExists: true,
  })),
  on(CheckSessionFailed, (state) => ({
    ...state,
    sessionExists: false,
    user: null,
  })),
);

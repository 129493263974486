import { Component } from '@angular/core';
import { FlowNodeTemplateKind } from '@fullstackagency/iam';
import { UiNodeTypeEnum } from '@ory/client';

@Component({
  selector: 'iam-with-prime-content',
  template: ` <ng-template iamMessages let-m>
      <iam-with-prime-messages [messages]="m" />
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Anchor" let-a>
      <a *ngIf="a.nodeType === UiNodeTypeEnum.A" [href]="a.href">
        {{ a.title }}
      </a>
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Image">{{
      FlowNodeTemplateKind.Image
    }}</ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Input" let-a>
      <div
        *ngIf="a.nodeType === UiNodeTypeEnum.Input"
        class="flex flex-column my-3 gap-1"
      >
        <label>{{ a.label }}</label>
        <input
          [autocomplete]="a.autocomplete"
          [disabled]="a.disabled"
          [name]="a.name"
          [required]="a.required"
          [type]="a.type"
          [value]="a.value"
          pInputText
        />
        <iam-with-prime-messages [messages]="a.messages" />
      </div>
    </ng-template>

    <ng-template
      [iamTemplate]="[
        FlowNodeTemplateKind.InputButton,
        FlowNodeTemplateKind.InputSubmit
      ]"
      let-a
    >
      <div *ngIf="a.nodeType === UiNodeTypeEnum.Input">
        <button
          [label]="a.label"
          [name]="a.name"
          [type]="a.type"
          [value]="a.value"
          pButton
          styleClass="p-button-rounded p-button-lg"
          class="w-full"
        ></button>
      </div>
    </ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Script">{{
      FlowNodeTemplateKind.Script
    }}</ng-template>

    <ng-template [iamTemplate]="FlowNodeTemplateKind.Text">{{
      FlowNodeTemplateKind.Text
    }}</ng-template>`,
})
export class ContentComponent {
  FlowNodeTemplateKind = FlowNodeTemplateKind;
  UiNodeTypeEnum = UiNodeTypeEnum;
}

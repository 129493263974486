import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration } from '../helpers/format-duration.helper';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: number | null, showEmpty?: boolean): string {
    return value
      ? formatDuration(value, false)
      : showEmpty
      ? formatDuration(0, true)
      : '';
  }
}

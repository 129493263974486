import { NavigationExtras, UrlTree } from '@angular/router';
import { createAction, props } from '@ngrx/store';

enum RouterActionTypes {
  GO = '[Router] Go',
  GO_BY_URL = '[Router] Go by Url',
  BACK = '[Router] Back',
  FORWARD = '[Router] Forward',
  REPLACE = '[Router] Replace',
}

export const Go = createAction(
  RouterActionTypes.GO,
  props<{
    payload: {
      path: string[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>(),
);

export const GoByUrl = createAction(
  RouterActionTypes.GO_BY_URL,
  props<{
    url: string | UrlTree;
  }>(),
);

export const Back = createAction(RouterActionTypes.BACK);

export const Forward = createAction(RouterActionTypes.FORWARD);

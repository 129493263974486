import { formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  LOCALE_ID,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { parseISO } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-closing-days-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="grid row-gap-4">
      <div class="col-12 flex flex-column gap-2">
        <label>{{ label }}</label>
        <p-calendar
          #calendar
          [showOtherMonths]="false"
          [formControl]="formControl"
          dateFormat="yy-mm-dd"
          dataType="string"
          [inline]="true"
          [numberOfMonths]="3"
          selectionMode="multiple"
        ></p-calendar>
      </div>
      <div
        *ngFor="let item of chips$ | async"
        class="col-12 flex flex-column gap-2"
      >
        <label>{{ item.key }}</label>
        <div
          class="p-multiselect p-multiselect-chip"
          style="padding: 0.25rem 0.75rem"
        >
          <div class="flex flex-wrap gap-2" style="max-width: 100%">
            <p-chip
              styleClass="p-multiselect-token"
              *ngFor="let chip of item.chips"
              [removable]="true"
              (onRemove)="onRemove(chip.value)"
            >
              <div>{{ chip.label }}</div>
            </p-chip>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClosingDaysInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ClosingDaysInputComponent),
      multi: true,
    },
  ],
})
export class ClosingDaysInputComponent
  implements ControlValueAccessor, Validator
{
  onChange: any = () => {};
  onTouch: any = () => {};
  onValidationChange: any = () => {};

  formControl: FormControl = new FormControl([]);

  @ViewChild('calendar') calendar: any;
  @Input() label: string = '';

  _value: string[] = [];
  chips$: BehaviorSubject<
    { key: string; chips: { label: string; value: string }[] }[]
  > = new BehaviorSubject<
    { key: string; chips: { label: string; value: string }[] }[]
  >([]);
  set value(value: string[]) {
    if (value !== this._value) {
      this._value = value;
      this.getChips();
      this.onChange(value);
      this.onTouch();
      this.onValidationChange();
    }
  }

  getChips() {
    const chips = this._value
      .sort()
      .reduce(
        (
          map: { key: string; chips: { label: string; value: string }[] }[],
          value
        ) => {
          const dateObj: Date = parseISO(value);
          const key = formatDate(dateObj, 'yyyy', this.locale);
          const index = map.findIndex((el) => el.key === key);
          const current = formatDate(dateObj, 'mediumDate', this.locale);
          if (index > -1) {
            map[index].chips.push({ label: current, value });
          } else {
            map.push({
              key,
              chips: [{ label: current, value }],
            });
          }
          return map;
        },
        []
      )
      .sort((a, b) => b.key.localeCompare(a.key));
    this.chips$.next(chips);
  }

  _disabled: boolean = false;
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  constructor(
    private readonly fb: FormBuilder,
    @Inject(LOCALE_ID) private readonly locale: string
  ) {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((values) => {
        this.value = values;
      });
  }

  writeValue(value: string[]): void {
    this.formControl.setValue(
      [...value].reverse().map((v) => parseISO(v)),
      { emitEvent: false }
    );
    this.value = [...value];
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }

  onRemove(value: string) {
    this.writeValue(this._value.filter((val) => value !== val));
  }
}

/* tslint:disable */
/* eslint-disable */
export enum AppointmentStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  NoShow = 'no-show',
  CanceledByCustomer = 'canceled-by-customer',
  Deleted = 'deleted',
  Completed = 'completed',
}

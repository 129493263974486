import { Component } from '@angular/core';

@Component({
  selector: '[app-topbar-message]',
  template: `
    <li class="layout-submenu-header">
      <h1>Messages</h1>
      <span>Today, you have new 4 messages</span>
    </li>
    <li class="layout-submenu-item">
      <img
        src="/assets/images/topbar/avatar-cayla.png"
        alt="mirage-layout"
        width="35"
      />
      <div class="menu-text">
        <p>Override the digital divide</p>
        <span>Cayla Brister</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-item">
      <img
        src="/assets/images/topbar/avatar-gabie.png"
        alt="mirage-layout"
        width="35"
      />
      <div class="menu-text">
        <p>Nanotechnology immersion</p>
        <span>Gabie Sheber</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-item">
      <img
        src="/assets/images/topbar/avatar-gaspar.png"
        alt="mirage-layout"
        width="35"
      />
      <div class="menu-text">
        <p>User generated content</p>
        <span>Gaspar Antunes</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
    <li class="layout-submenu-item">
      <img
        src="/assets/images/topbar/avatar-tatiana.png"
        alt="mirage-layout"
        width="35"
      />
      <div class="menu-text">
        <p>The holistic world view</p>
        <span>Tatiana Gagelman</span>
      </div>
      <i class="pi pi-angle-right"></i>
    </li>
  `,
  styles: [],
})
export class TopbarMessageComponent {}

import { Match, Matcher } from '@zxcvbn-ts/core/dist/types';

export const minLengthMatcher = (minLength: number): Matcher => ({
  Matching: class MatchMinLength {
    minLength: number = minLength ?? 8;
    match({ password }: { password: string }) {
      const matches: Match[] = [];
      if (password.length < this.minLength) {
        matches.push({
          pattern: 'minLength',
          token: password,
          i: 0,
          j: password.length - 1,
        });
      }
      return matches;
    }
  },
  feedback() {
    return {
      warning: 'Your password is not long enough',
      suggestions: [],
    };
  },
  scoring(match) {
    return match.token.length * 10;
  },
});

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { HideLoading, ShowLoading } from '../store/actions/layout.action';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  const requestId = uuid();
  store.dispatch(
    ShowLoading({
      id: requestId,
      context: `${req.method} ${req.url}`,
    }),
  );
  return next(req).pipe(
    finalize(() => {
      store.dispatch(
        HideLoading({
          id: requestId,
        }),
      );
    }),
  );
};

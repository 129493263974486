import {
  ChangeDetectionStrategy,
  Component,
  Input,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

@Component({
  selector: 'app-checkbox-block',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="p-4 bg-primary {{ styleClass }} hover"
      [ngClass]="{
        'bg-primary': (value$ | async),
        'surface-200': !(value$ | async)
      }"
      (click)="toggle()"
      style="display: flex; justify-content: space-between;"
    >
      <span [ngClass]="{ 'font-bold': (value$ | async) }">{{ label }}</span>
      <fa-icon
        [icon]="['fas', 'circle-check']"
        size="xl"
        class="ml-4 text-600"
        [ngClass]="{
          'text-white': (value$ | async),
          'text-gray-500': !(value$ | async)
        }"
      >
      </fa-icon>
    </div>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxBlockComponent),
      multi: true,
    },
  ],
})
export class CheckboxBlockComponent implements ControlValueAccessor {
  @Input() name: string = 'checkbox';
  @Input() label: string = '';
  @Input() style!: object;
  @Input() styleClass!: string;

  private _value$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  value$: Observable<boolean> = this._value$.pipe(shareReplay());

  onChange: any = () => {};
  onTouch: any = () => {};

  set value(value: boolean) {
    this._value$.next(value);
    this.onChange(value);
    this.onTouch();
  }

  disabled: boolean = false;

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggle(): void {
    this.value = !this._value$.getValue();
  }
}

import { Component, Input } from '@angular/core';
import { UiText } from '@ory/client';
import { Message } from 'primeng/api';

@Component({
  selector: 'iam-with-prime-messages',
  template: `
    <p-messages
      [closable]="false"
      [enableService]="false"
      [value]="messageList"
    />
  `,
})
export class MessagesComponent {
  @Input({
    required: true,
    alias: 'messages',
  })
  set _messages(messages: UiText[]) {
    this.messageList = messages.map<Message>((message) => ({
      detail: message.text,
      severity: message.type,
    }));
  }

  messageList: Message[] = [];
}

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { selectCompany } from '@app/app/core/store/selectors/company.selector';
import { Company } from '@generated/models';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs';

export const firstAccessGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);

  return store.select(selectCompany).pipe(
    filter((company): company is Company => company !== null),
    take(1),
    map((company) => {
      return company.activities.length
        ? router.createUrlTree(['/', 'agenda'])
        : true;
    }),
  );
};

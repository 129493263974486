import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-operator-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="size === 'small'">
      <div class="relative">
        <p-avatar shape="circle">
          <div class="w-full h-full" *ngIf="image; else placeholder">
            <p-image
              [imageStyle]="{ 'object-fit': 'cover' }"
              styleClass="w-full h-full"
              [src]="image | storagePath"
            />
          </div>
          <ng-template #placeholder>
            <i class="pi pi-at"></i>
          </ng-template>
        </p-avatar>
        <div
          *ngIf="color"
          class="absolute z-10 p-avatar border-circle w-1rem h-1rem"
          [ngStyle]="{ right: '-.25rem', bottom: '-.25rem', background: color }"
        ></div>
      </div>
    </ng-container>

    <ng-container *ngIf="size === 'large'">
      <div class="relative">
        <p-avatar size="xlarge" *ngIf="image; else placeholder" shape="circle">
          <div class="w-full h-full">
            <p-image
              [src]="image | storagePath"
              [imageStyle]="{ 'object-fit': 'cover' }"
              styleClass="w-full h-full"
            />
          </div>
        </p-avatar>
        <ng-template #placeholder>
          <p-avatar size="xlarge" shape="circle">
            <i class="pi pi-at" style="font-size:2rem"></i>
          </p-avatar>
        </ng-template>
        <div
          *ngIf="color"
          class="absolute z-10 p-avatar border-circle"
          [ngStyle]="{ right: '-.5rem', bottom: '-.5rem', background: color }"
        ></div>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class OperatorAvatarComponent {
  @Input() image: string | null | undefined;
  @Input() color: string | null | undefined;
  @Input() size: 'small' | 'large' = 'small';
}
